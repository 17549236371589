
import { Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation, Output, EventEmitter, OnDestroy, TemplateRef } from '@angular/core';
import { AppService } from '../../../core/services/base.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { SalesService } from '../../../services/sales.service';
import { InstallmentNumber, SaleAmount, SharedService, Status } from '../../../shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../shared/excel.service';
import { SellerService } from '../../../services/seller.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { LoggingService } from '../../../core/services/log.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, ValidationErrors, ValidatorFn, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { RefundType } from '../../../core/classes/interfaces.interface';
import { ISortingType } from '../../../shared/models/base';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import moment from 'moment';
import * as _ from 'lodash';
import swal from 'sweetalert2';

moment.locale('it');

@Component({
  selector: 'app-sale-analysis',
  templateUrl: './sale-analysis.component.html',
  styleUrls: ['./sale-analysis.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaleAnalysisComponent implements OnInit, OnDestroy {
  @Output() deletedChip = new EventEmitter<any>();
  states = [
    { 'state': 'final', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false },
    { 'state': 'initial', 'formStatusValid': false }];

  @ViewChild('dataTable') table: any;
  @ViewChildren('table') subTable: any;
  @ViewChild('saleDataModal') saleDataModal: TemplateRef<any>;
  @ViewChild('saleEditModal') saleEditModal: TemplateRef<any>;
  @ViewChild('addRecurrentInstalmentModal') addRecurrentInstalmentModal: TemplateRef<any>;
  @ViewChild('refundModal') refundModalTemplate: TemplateRef<any>;
  @ViewChild('infoModal') infoModalTemplate: TemplateRef<any>;

  activePage: String = "sa";
  rows: any[] = [];
  totalRows: number = 0;
  filterQueryParameter: any;
  statusUrl: any;
  sortProp: any;
  allStatus: any = [
    { "name": "active" },
    { "name": "new" }
  ]

  profile : any;
  mercantDetailsList : any ;
  merchantDetail : any ;

  selectedUser : any;

  newVal : boolean;
  oldVal : boolean = false; 
  includiScaduti : boolean = false;
  expiredOptions : boolean = true;
  viewPaymentLink : boolean = false;

  sortOptions = [
    InstallmentNumber,
    Status,
    SaleAmount,
    // paymentType
  ]
  
  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  options: any = {};
  allSales: any;
  tableRecordNumbers: number;
  tablePage: number = 1;

  searchForm: UntypedFormGroup;
  sortForm: UntypedFormGroup;
  sortings: ISortingType[];

  modalRef : BsModalRef

  saleToRetrieveData: any = null;

  minInstalmentAmount: number = 0;
  saleFrozenMaxDays: number = 0;

  saleToEdit: any = null;
  saleEditForm = this.formBuilder.group({
    instalments: this.formBuilder.array([])
  })

  saleToAddRecurrentInstalment: any = null;
  recurrentInstalmentsToAdd: any = null;
  addRecurrentInstalmentForm = this.formBuilder.group({
    expiryDate: [null, [Validators.required]],
    expiryDay: [null, [Validators.required]]
  })

  instalmentToBeRefunded: any = null;
  refundForm = this.formBuilder.group({
    type: [RefundType.FULL],
    amount: [null, [Validators.min(this.minInstalmentAmount)]],
  })

  RefundType = RefundType;

  notifySelectedUserChange : Subscription;

  infoForm: UntypedFormGroup;
  hideSaleInfo: boolean;

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    showWeekNumbers: false,
    dateInputFormat: 'DD/MM/YYYY',
    rangeInputFormat: 'DD/MM/YYYY'
  }

  days=[
    {number:1},
    {number:2},
    {number:3},
    {number:4},
    {number:5},
    {number:6},
    {number:7},
    {number:8},
    {number:9},
    {number:10},
    {number:11},
    {number:12},
    {number:13},
    {number:14},
    {number:15},
    {number:16},
    {number:17},
    {number:18},
    {number:19},
    {number:20},
    {number:21},
    {number:22},
    {number:23},
    {number:24},
    {number:25},
    {number:26},
    {number:27},
    {number:28}
  ]

  constructor(
    private excelService:ExcelService,
    private appService: AppService,
    private router: Router,
    public colors: ColorsService,
    public http: HttpClient,
    private route: ActivatedRoute,
    public settings: SettingsService,
    private translate: TranslateService,
    private salesService: SalesService,
    public sharedService: SharedService,
    public sellerService: SellerService,
    private configurationService: ConfigurationService,
    private logger : LoggingService,
    private modalService : BsModalService,
    private formBuilder: UntypedFormBuilder,
    private decimalPipe: DecimalPipe
  ) {
      this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'), null);
      var self = this;
      this.translate.stream('sort').subscribe(function(obj:any) {
        console.log('translate.get - obj', obj)
        self.sortOptions[0].showField = obj.instalment_number;
        self.sortOptions[1].showField = obj.state;
        self.sortOptions[2].showField = obj.sale_amount;
        // self.sortOptions[3].showField = obj.payment_type;
      });

      // Overrides default total/empty messages
      translate.stream("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );
      translate.stream("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);

      this.searchForm = this.formBuilder.group({
        customerName: [null],
        customerSurname: [null],
        customerEmail: [null],
        amountFrom: [null],
        amountTo: [null],
        //dateRange: [{ startDate: moment().subtract(30, 'days'), endDate: moment() }, Validators.required],
        //dateFrom: [{ startDate: moment().subtract(30, 'days'), endDate: moment().subtract(30, 'days') }, Validators.required],
        //dateTo: [{ startDate: moment(), endDate: moment() }, Validators.required],
        dateRange: [[ moment().subtract(30, 'days').toDate(), moment().toDate() ], Validators.required],
        status: [null],
        userEmail: [null],
        service: [null],
        idMerchant: [null],
        idExternal: [null]
      });

      this.sortForm = this.formBuilder.group({
        viewExpired: [false],
        viewRefund: [false],
        viewDispute: [false],
        sorting: ['']
      });

      this.sortings = [
        { id: 0, name: "", description: "sort.default" },
        { id: 1, name: "instalmentNumber", description: "sort.instalment_number" },
        { id: 2, name: "status", description: "sort.state" },
        { id: 3, name: "amount", description: "sort.sale_amount" }
      ]
      
      this.hideSaleInfo = localStorage.getItem('hideSaleInfo') ?
        JSON.parse(localStorage.getItem('hideSaleInfo')) : false;

      this.infoForm = this.formBuilder.group({
        hideInfo: [this.hideSaleInfo, Validators.requiredTrue]
      });
  }
  
  ngOnInit() {
    this.profile = localStorage.getItem('profile')
    localStorage.setItem( "saleDate" , "1" )
    
    // this.initSaleTable();
    // this.getAllSales();
    this.selectedUser = JSON.parse( localStorage.getItem("selectedUser") )
    
    //this.getAllSales( null, this.selectedUser ? this.selectedUser.idMerchant : null );
    this.prepareForSearch();
    
    this.sharedService.sortTableObserver.subscribe((name: any) => {
      this.sortProp = name;
      var that = this;
      setTimeout(function () {
        that.rows = [...that.rows]
      })
    })

    this.notifySelectedUserChange = this.sharedService.selectedUserChanged.subscribe((value) => {
      this.selectedUser = JSON.parse( localStorage.getItem("selectedUser") )

      if (this.selectedUser) {
        this.searchForm.controls.idMerchant.setValue(this.selectedUser.idMerchant);
        this.searchForm.controls.idMerchant.disable();
      } else {
        this.searchForm.controls.idMerchant.reset();
        this.searchForm.controls.idMerchant.enable();
      }

      this.prepareForSearch();
    })

    if( localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER' ) {
      this.getMerchantDetail()
    } else {
      this.canViewPaymentLink()
    }

    this.getMinInstalmentAmountConfiguration();
    this.getSaleFrozenMaxDaysConfiguration();

    setTimeout(() => {
      if (!this.hideSaleInfo) {
        this.openInfoModal();
      }
    });
  }

  ngDoCheck() {
    this.newVal = this.hasSelectedAlias()
    if (!this.filterQueryParameter){
      this.filterQueryParameter = this.initDefaultRangeDates();
    }

    if ( this.isValChanged( this.oldVal , this.newVal) ){
      this.oldVal = this.newVal
      // this.getAllSales( this.filterQueryParameter , this.selectedUser ? this.selectedUser.idMerchant : null )
    } 
  }

  initSaleTable( isFromEmit = null ) {
    this.options = {};
    if( !localStorage.getItem("saleDate") ) {
      this.filterQueryParameter = "";
    }
    let selectedUser = JSON.parse( localStorage.getItem("selectedUser") )
    if (selectedUser) {
      this.options.userSelected = selectedUser
      this.selectedUser = JSON.parse( localStorage.getItem("selectedUser") )
    } else {
      this.selectedUser = null
    }

    // if (localStorage.getItem("saleDate")) {
    //   // this.options.dates = localStorage.getItem("saleDate")
    //   this.options.dates = this.initDefaultRangeDates( "chip" )
    // }

    this.statusUrl = localStorage.getItem("saleStatus");
    if (this.statusUrl) {
      if (this.statusUrl == 'pending') {
        this.options.status = 'new'
        this.filterQueryParameter = "status=new"
      }
      else if (this.statusUrl == 'paid') {
        this.options.status = 'closed'
        if (localStorage.getItem('saleDate')) {
          this.filterQueryParameter = this.initDefaultRangeDates() + "&status=closed"
        }
        else {
          this.filterQueryParameter = "status=closed"
        }
      }
    } else {
      if( isFromEmit == null ){
        this.filterQueryParameter = this.initDefaultRangeDates()
      }
      this.getAllSales( this.filterQueryParameter ? this.filterQueryParameter : null , this.selectedUser ? this.selectedUser.idMerchant : null )
    }
  }

  updateSaleTable() {
    this.getAllSales( this.filterQueryParameter ? this.filterQueryParameter : null , this.selectedUser ? this.selectedUser.idMerchant : null )
  }

  async getAllSales(queryParams = null, id = null, sortingParams = null, exporting = false) {

    swal.showLoading();

    if (queryParams == null) {
      queryParams = "startDate=" + moment().subtract(30, 'days').format("YYYY-MM-DD") +
        "&endDate=" + moment().format("YYYY-MM-DD");
    }

    if (!exporting) {
      queryParams += '&paging=true&page=' + this.tablePage + '&rows=' + this.tableRecordNumbers;
    }

    if (sortingParams) {
      queryParams += '&' + sortingParams;
    }

    const res = await this.salesService.getAllSales(queryParams, id).toPromise();
    let results = [];
    if (res) {
      results = res.results;
      if (!exporting) {
        this.allSales = res.results;
        this.rows = res.results;
        this.totalRows = res.total;
        // this.rows = [...this.rows];
        /*if ( !this.includiScaduti ) {
          this.rows = this.excludeExpiredSales();
        }*/
        /*if (!this.sortForm.controls.viewExpired.value || this.sortForm.controls.viewRefund.value || this.sortForm.controls.viewDispute.value) {
          this.rows = this.excludeFlagSales();
        }
        this.rows = [...this.rows];*/
      }
    }
    swal.close();
    return results;
  }

  getMinInstalmentAmountConfiguration() {
    this.configurationService.getConfiguration('MINIMUM_INSTALMENT_AMOUNT').subscribe(res => {
      this.minInstalmentAmount = +res.valueConfiguration;
    });
  }

  getSaleFrozenMaxDaysConfiguration() {
    this.configurationService.getConfiguration('SALE_FROZEN_MAX_DAYS').subscribe(res => {
      this.saleFrozenMaxDays = +res.valueConfiguration;
    });
  }

  excludeExpiredSales() {
    let result : any = []
    this.allSales.forEach( element => {
      let date = moment( element.startDate , 'YYYY-MM-DD' ).toDate()
      if( this.isExpired( element ) ) {
        // console.log( 'trovata vendita New e scadenza passata' , date , moment().toString )
      } else {
        result.push(element)
      }
    })
    return result
  }

  excludeFlagSales() {
    let result : any = []
    this.allSales.forEach( element => {
      if( (!this.sortForm.controls.viewExpired.value && this.isExpired( element )) ||
        (this.sortForm.controls.viewRefund.value && !this.isRefund( element )) ||
        (this.sortForm.controls.viewDispute.value && !this.isDispute( element )) ) {
      } else {
        result.push(element)
      }
    })
    return result
  }

  getSaleRefundStatus(row) {
    if (row.status == 'active' || row.status == 'closed' || row.status == 'canceled') {
      if (row.amount == row.refundedAmount) {
        return 'refunded_fully';
      } else if (row.refundedAmount > 0) {
        return 'refunded_partially';
      }
    }
    return null;
  }

  isSaleRefundPending(row) {
    return (row.status == 'active' || row.status == 'closed' || row.status == 'canceled') && row.refundTotalAmount > row.refundedAmount;
  }

  getInstalmentStatus(row) {
    if (row.instalmentStatus == 'PAID' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY') {
      if (row.amount == row.refundedAmount) {
        return 'REFUNDED_FULLY';
      } else if (row.refundedAmount > 0) {
        return 'REFUNDED_PARTIALLY';
      }
    } else if (this.isInstalmentExpired(row)) {
      return 'EXPIRED';
    }
    return row.instalmentStatus;
  }

  isRefundPending(row) {
    return (row.instalmentStatus == 'PAID' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY') &&
      row.refundTotalAmount > row.refundedAmount;
  }

  isPaymentMethodGoingToExpire(row) {
    if (!row.paymentMethodType || !row.paymentMethodExpMonth || !row.paymentMethodExpYear) {
      return false;
    }
    if (row.status != 'active' ||
      (row.paymentType != 'RATEIZZATO' && row.paymentType != 'RICORRENTE')) {
      return false;
    }
    const lastExpirationDate = moment().toDate();
    return (row.paymentMethodExpYear == lastExpirationDate.getFullYear() && 
      row.paymentMethodExpMonth == lastExpirationDate.getMonth() + 1);
  }

  isPaymentMethodExpired(row) {
    if (!row.paymentMethodType || !row.paymentMethodExpMonth || !row.paymentMethodExpYear) {
      return false;
    }
    if (row.status != 'active' ||
      (row.paymentType != 'RATEIZZATO' && row.paymentType != 'RICORRENTE')) {
      return false;
    }
    const lastExpirationDate = moment().toDate();
    return (row.paymentMethodExpYear < lastExpirationDate.getFullYear() ||
      (row.paymentMethodExpYear == lastExpirationDate.getFullYear() && 
        row.paymentMethodExpMonth < lastExpirationDate.getMonth() + 1));
  }

  onPage(event) {
    if (this.tablePage != event.offset + 1) {
      this.tablePage = event.offset + 1;
      this.getAllSales( this.getQueryParams(), 
        this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant(),
        this.getSortingParams() );
    }
  }

  async toggleExpandRow(row,expanded) {
    if (!expanded) {
      await this.getSalesDetails(row.idSale, row);
    }
    this.table.rowDetail.toggleExpandRow(row);
    setTimeout(() => {
      const subTable = this.subTable.filter(subTable => subTable.element.id == row.idSale)[0];
      row['salesDetails'].forEach(saleInstalment => {
        if (saleInstalment.instalmentRefs && saleInstalment.instalmentRefs.length) {
          if (subTable) {
            setTimeout(() => {
              subTable.rowDetail.toggleExpandRow(saleInstalment);
            })
          }
        }
      })
    })
  }

  async getSalesDetails(idSale, row) {
    swal.showLoading();
    let res = await this.salesService.getSalesDetails(idSale).toPromise();
    row['salesDetails'] = res.results;
    swal.close();
    return res.results;
  }

  searchEvent(event) {
    console.log('searchEvent - event' , event);
    this.filterQueryParameter = this.getQueryParamsOfSearch(event);
    // console.log( 'SearchEvent' , event )
    // this.getAllSales(queryParamSearch, null)
    this.getAllSales( this.filterQueryParameter , this.selectedUser ? this.selectedUser.idMerchant : null );
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  sortEvent(event) {
    // this.sortProp = name;
    const name = event.target.value;
    this.sortProp = name;
    console.log('sortEvent - name' , name);
    // this.rows = [...this.sharedService.sortArray( this.rows , name)];
    var that = this;
    setTimeout(function () {
      that.rows = [...that.rows]
    })
  }

  getQueryParamsOfSearch(searchItems) {
    console.log( 'getQueryParamsOfSearch' , searchItems )
    let queryParamsSearch = "";
    queryParamsSearch = Object.keys(searchItems).map(key => key + '=' + searchItems[key]).join('&');
    /* if (localStorage.getItem("saleDate"))
      queryParamsSearch += this.initDefaultRangeDates() */
    if (localStorage.getItem("saleStatus"))
      queryParamsSearch += ("&status=" + this.options.status)
    return queryParamsSearch;
  }

  private getQueryParams() {
    let queryParamsSearch = "";
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (key != 'idMerchant') {
          /*if (key == 'dateRange') {
            if (queryParamsSearch) {
              queryParamsSearch += "&"
            }
            queryParamsSearch += "startDate=" + this.searchForm.controls[key].value.startDate.format("YYYY-MM-DD");
            queryParamsSearch += "&"
            queryParamsSearch += "endDate=" + this.searchForm.controls[key].value.endDate.format("YYYY-MM-DD");
          }*/
          if (key.startsWith('date')) {
            if (queryParamsSearch) {
              queryParamsSearch += "&"
            }
            if (key == 'dateFrom') {
              queryParamsSearch += "startDate=" + this.searchForm.controls[key].value.startDate.format("YYYY-MM-DD");
            } else if (key == 'dateTo') {
              queryParamsSearch += "endDate=" + this.searchForm.controls[key].value.endDate.format("YYYY-MM-DD");
            } else if (key == 'dateRange') {
              queryParamsSearch += "startDate=" + moment(this.searchForm.controls[key].value[0]).format("YYYY-MM-DD") + "&" +
                "endDate=" + moment(this.searchForm.controls[key].value[1]).format("YYYY-MM-DD");
            }
          } else {
            if (Array.isArray(this.searchForm.controls[key].value)) {
              if (!!this.searchForm.controls[key].value.length) {
                if (queryParamsSearch) {
                  queryParamsSearch += "&"
                }
                queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
              }
            } else {
              if (queryParamsSearch) {
                queryParamsSearch += "&"
              }
              queryParamsSearch += key + "=" + this.searchForm.controls[key].value
            }
          }
        }
      }
    }

    let excludeExpired = true;
    let paymentType = null;
    for (let key in this.sortForm.controls) {
      if (this.sortForm.controls[key].value) {
        if (key != 'sorting') {
          switch (key) {
            case 'viewExpired':
              excludeExpired = !this.sortForm.controls[key].value;
              break;
            case 'viewRefund':
              if (!paymentType) {
                paymentType = [];
              }
              paymentType.push('RECHARGE_EXT_REFUND');
              break;
            case 'viewDispute':
              if (!paymentType) {
                paymentType = [];
              }
              paymentType.push('CHARGE_DISPUTE');
              break;
          }
        }
      }
    }
    if (excludeExpired) {
      if (queryParamsSearch) {
        queryParamsSearch += "&"
      }
      queryParamsSearch += "excludeExpired=" + excludeExpired
    }
    if (paymentType) {
      if (queryParamsSearch) {
        queryParamsSearch += "&"
      }
      queryParamsSearch += "paymentType=" + paymentType
    }

    return queryParamsSearch;
  }

  private getIdMerchant() {
    let idMerchant = null;
    if (this.searchForm.controls['idMerchant'].value) {
      idMerchant = this.searchForm.controls['idMerchant'].value
    }
    return idMerchant;
  }

  private getSortingParams() {
    let sortingParamsSearch = "";
    if (this.sortForm.controls['sorting'].value) {
      sortingParamsSearch += "orderBy=" + this.sortForm.controls['sorting'].value + "&direction=ASC";
    }
    return sortingParamsSearch;
  }

  prepareForSearch() {
    if (this.searchForm.valid) {
      this.tablePage = 1;
      this.getAllSales( this.getQueryParams(), 
        this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant(),
        this.getSortingParams() );
      if( this.table ) {
        this.table.offset = 0;
      }
    }
  }

  prepareForCancel() {
    this.searchForm.reset({
      //dateRange: { startDate: moment().subtract(30, 'days'), endDate: moment() },
      //dateFrom: { startDate: moment().subtract(30, 'days'), endDate: moment().subtract(30, 'days') },
      //dateTo: { startDate: moment(), endDate: moment() },
      dateRange: [ moment().subtract(30, 'days').toDate(), moment().toDate() ],
      idMerchant: this.selectedUser ? this.selectedUser.idMerchant : null
    });
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  onSortingChange(field: ISortingType) {
    /*this.sortProp = field.name;
    console.log('sortEvent - name' , this.sortProp);
    setTimeout(() => {
      this.rows = [...this.rows]
    })*/
    this.prepareForSearch();
  }

  statusSelectedEvent(event, row) {
    row.statusSelectOpened = false
    row.status = event.name
  }

  hideSelectEvent(row) {
    row.statusSelectOpened = false
  }
  
  initLastWeekRangeDates() {
    var date = new Date();
    let today = this.sharedService.formatDateForServer(date);
    date.setDate(date.getDate() - 7);
    let beforeAWeek = this.sharedService.formatDateForServer(date);
    let queryParamsDate = "startDate=" + beforeAWeek + "&endDate=" + today;
    return queryParamsDate;
  }

  initDefaultRangeDates( return_type : string = null ) {
    var date = new Date();
    let today = this.sharedService.formatDateForServer(date);
    date.setDate(date.getDate() - 30);
    let beforeDate = this.sharedService.formatDateForServer(date);
    let queryParamsDate;
    if ( return_type == null ){
      queryParamsDate = "startDate=" + beforeDate + "&endDate=" + today;
    } else if ( return_type == "obj" ) {
      queryParamsDate = { date : { startDate : beforeDate , endDate : today } }
    } else if ( return_type == "chip" ) {
      queryParamsDate = beforeDate + "/" + today
    }
    return queryParamsDate;
  }

  async export() {
    const rows = await this.getAllSales( this.getQueryParams(), 
      this.selectedUser ? this.selectedUser.idMerchant : this.getIdMerchant(),
      this.getSortingParams(), true );
    var data = [];
    var saleHeader = {};
    if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'RESELLER') {
      saleHeader = {
        merchant: 'Commerciante'
      }
    }
    saleHeader = {
      ...saleHeader,
      service: 'Nome vendita',
      name: 'Cognome nome',
      saleDate: 'Data vendita',
      endDate: 'Fine vendita',
      saleAmount: 'Totale importo vendita',
      paymentType: 'Tipologia vendita',
      instalmentNumber: 'Numero rate',
      status: 'Stato vendita',
      paymentMethodType: 'Metodo di pagamento',

    }
    data.push(saleHeader)
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      var sale = {};
      if (localStorage.getItem('profile') == 'SUPERADMIN' || localStorage.getItem('profile') == 'RESELLER') {
        sale = {
          merchant: element.merchantCompanyName
        }
      }
      sale = {
        ...sale,
        service: element.service,
        name: element.fullName ? element.fullName : element.surname + ' ' + element.name,
        saleDate: element.startDate ? element.startDate : 'Non disponibile',
        endDate: element.endDate ? element.endDate : 'Non disponibile',
        saleAmount: element.amount,
        paymentType: element.paymentType,
        instalmentNumber: element.instalmentNumber,
        status: element.status,
        paymentMethodType: element.paymentMethodType ?
          (element.paymentMethodType == 'card' || element.paymentMethodType == 'card_present' ?
              'Carta ' + element.paymentMethodCode + (element.paymentMethodCountry ? ' (' + element.paymentMethodCountry + ')' : '') +
                ' ' + (element.paymentMethodLast4 || '----') + ' (' + (this.decimalPipe.transform(element.paymentMethodExpMonth, '2.0-0') || '--') +
                '/' + (element.paymentMethodExpYear || '----') + ')' :
            (element.paymentMethodType == 'sepa_debit' ?
              'Sepa' + (element.paymentMethodCountry ? ' (' + element.paymentMethodCountry + ')' : '') + ' ' + (element.paymentMethodLast4 || '----') :
            (element.paymentMethodType == 'paypal' ? 'Paypal' :
            (element.paymentMethodType == 'klarna' ? 'Klarna' :
            (element.paymentMethodType == 'satispay' ? 'Satispay' : ''))))) : ''
      }
      data.push(sale)
    }
    this.excelService.export(data, 'Analisi Vendite' + new Date().getTime())
  }

  /* hasSelectedAlias() {
    var cur_use = localStorage.getItem('selectedUser')
    if ( cur_use != null) {
      this.selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      return true
    } else {
      this.selectedUser = null;
      return false
    }
  } */

  hasSelectedAlias() {
    var cur_use = JSON.parse(localStorage.getItem('selectedUser'))
    if ( cur_use != null && this.selectedUser == null ) {
      this.selectedUser = cur_use
      return true
    } else if ( cur_use != null && this.selectedUser != null ) {
      if( parseInt(this.selectedUser.idMerchant) !== parseInt(cur_use.idMerchant) ) {
        this.selectedUser = cur_use;
        this.oldVal = false
        return true
      }
    } else {
      this.selectedUser = null;
      return false
    }
  }

  // NON UTILIZZATO - VERIFICARE
  setUserFilter() {
    if( this.hasSelectedAlias() ) {
      this.getAllSales( null , this.selectedUser ? this.selectedUser.idMerchant : null )
    } else {
      console.log('hasSelectedAlias FALSE')
    }
  }

  chipDeleted() {
    this.deletedChip.emit()
  }

  getMerchantDetail() {
    this.sellerService.getSellerDetails( localStorage.getItem('idMerchant') ).subscribe((res) => {
      this.merchantDetail = res;
      this.canViewPaymentLink()
      this.getMerchantStatusTos();
    })
  }

  isValChanged( old , current ) {
    if( old == current ) {
      return false;
    } else {
      return true
    }
  }

  onExclusionChange( event ) {
    this.includiScaduti = event
    this.sortForm.controls.viewExpired.setValue(event);
    // this.initSaleTable('1')
    if( event != true ) {
      this.rows = this.excludeExpiredSales()
    } else {
      if( this.allSales ) {
        this.rows = this.allSales;
      }
    }
  }

  isExpired( element ) {
    let expirationDate = element.startDate
    if (element.paymentType == 'VARIABLE') {
      expirationDate = element.endDate
    }
    //let date = new Date( expirationDate )
    // console.log( 'element.' , element )
    if( ( element.status == "new" && ( moment(expirationDate, 'YYYY-MM-DD').isBefore(moment() , 'day') ) ) ||
      element.status == "expired_mandatory") { /* && !moment(date).isSame(moment() , 'day' ) */
      // console.log( 'trovata vendita New e scadenza passata' , element.startDate )
      return true
    } else {
      return false
    }
  }

  isInstalmentExpired( element ) {
    if( element.instalmentStatus == "PLANNED" && moment( element.expiryDate , 'YYYY-MM-DD' ).isBefore(moment() , 'day') ) {
      return true
    } else {
      return false
    }
  }

  isRefund( element ) {
    if ( element.paymentType == 'RECHARGE_EXT_REFUND' ) {
      return true
    } else {
      return false
    }
  }

  isDispute( element ) {
    if ( element.paymentType == 'CHARGE_DISPUTE' ) {
      return true
    } else {
      return false
    }
  }

  onExpiredExclusionChange( event ) {
    this.sortForm.controls.viewExpired.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onRefundExclusionChange( event ) {
    this.sortForm.controls.viewRefund.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onDisputeExclusionChange( event ) {
    this.sortForm.controls.viewDispute.setValue(event);
    /*this.onFlagExclusionChange();*/
    this.prepareForSearch();
  }

  onFlagExclusionChange() {
    if (!this.sortForm.controls.viewExpired.value || this.sortForm.controls.viewRefund.value || this.sortForm.controls.viewDispute.value) {
      this.rows = this.excludeFlagSales()
    } else {
      if( this.allSales ) {
        this.rows = this.allSales;
      }
    }
  }

  sendReminder( row ) {
    this.logger.log( 'sendReminder()' , row , 200)
    // this.getSalesDetails( row.idSale, row )
    let idInstalment : any;

    swal.showLoading();
    
    this.salesService.getSalesDetails( row.idSale ).subscribe((res)=> {
      console.log( 'rowres.results[0].idInstalment' , res , res.results[0].id )
      
      if (row.paymentType == 'VARIABLE') {
        idInstalment = res.results.filter(item => !moment(item.expiryDate, 'YYYY-MM-DD').isBefore(moment(), 'day'))
          .sort((a, b) => a.expiryDate - b.expiryDate)[0]?.id || res.results[res.results.length - 1].id;
      } else {
        idInstalment = res.results[0].id
      }

      this.salesService.sendInstalmentMail(idInstalment).subscribe((response)=> {
        swal.close()
        console.log(' response invio mail' , response )
        if( response === 'OK' ) {
          swal.fire( this.translate.instant('sale.send_instalment_reminder_ok') , this.translate.instant('sale.sended_instalment_ok' , {customer_email : row.email} ) , "success" )
        } else {
          swal.fire( "Error" , "" , "error" )
        }
      })

    })
    
  }

  canRequestData(row) {
    return !row.fromExternalApplication &&
      (localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER') &&
      (this.merchantDetail && !this.merchantDetail.softwareHouse && this.merchantDetail.tos_accepted && this.merchantDetail.state == 'ACTIVE');
  }

  async requestSaleData(row) {
    let res = await this.salesService.requestSaleData(row.idSale).toPromise();
    if( res ) {
      this.saleToRetrieveData = res;
    }
  }

  async openModalSaleData(row) {
    await this.requestSaleData(row);
    this.modalRef = this.modalService.show(this.saleDataModal, { class: 'modal-lg' } )
  }

  sendChangeReminder( row ) {
    this.logger.log( 'sendChangeReminder()' , row , 200)

    swal.showLoading();

    this.salesService.sendSaleEditedMail(row.idSale).subscribe((response)=> {
      swal.close()
      console.log(' response invio mail' , response )
      if( response === 'OK' ) {
        swal.fire( this.translate.instant('sale.send_sale_change_reminder_ok') , this.translate.instant('sale.sended_sale_change_ok' , {customer_email : row.email} ) , "success" )
      } else {
        swal.fire( "Error" , "" , "error" )
      }
    })

  }

  canViewPaymentLink() {
    if( localStorage.getItem('profile') != 'SUPERADMIN' && localStorage.getItem('profile') != 'RESELLER' ) {
      this.viewPaymentLink = this.merchantDetail.linkFirstPayment
    } else if( localStorage.getItem('profile') == 'SUPERADMIN' ) {
      this.viewPaymentLink = true;
    } else {
      this.viewPaymentLink = false;
    }
  }

  getMerchantStatusTos() {
    this.sellerService.statusTos( localStorage.getItem('idMerchant') ).subscribe( (res) => {
      this.merchantDetail.tos_accepted = res.accepted
    })
  }

  getSaleFrozenExpirationDays(row) {
    const datesDiffMillis = moment().diff(moment(new Date(row.tsStatusLastModified)));
    const maxDaysMillis = moment.duration(this.saleFrozenMaxDays, 'days').as('milliseconds');
    return Math.round(moment.duration(maxDaysMillis - datesDiffMillis).as('days'));
  }

  canEditSale(row) {
    return (row.paymentType == 'RATEIZZATO' || row.paymentType == 'RICORRENTE') &&
      (localStorage.getItem('profile') == 'SUPERADMIN' ||
        (this.merchantDetail && !this.merchantDetail.softwareHouse && this.merchantDetail.tos_accepted && this.merchantDetail.state == 'ACTIVE'));
  }

  canAddRecurrentInstalment(row) {
    return row.paymentType == 'RICORRENTE' && localStorage.getItem('profile') == 'SUPERADMIN';
  }

  cancelSale(row) {
    swal.fire({
      icon: "warning",
      title: this.translate.instant('sale.swal.warning_cancel_sale_title'),
      text: this.translate.instant('sale.swal.warning_cancel_sale_text'),
      confirmButtonText: this.translate.instant('sale.swal.confirm_cancel_text'),
      confirmButtonColor: "#ed2d2d",
      showCancelButton : true,
      cancelButtonText: this.translate.instant('general.cancel')
    }).then((result) => {
      if( result.value ) {
        swal.showLoading();
        this.salesService.cancelSale( row.idSale ).subscribe((res)=> {
          this.prepareForSearch()
        })
      } else {
        swal.close()
      }
    })
  }

  isInstalmentEditable(element) {
    const lastNotPlannedElement = [...this.saleToEdit['salesDetails']].reverse().find(item => item.instalmentStatus != 'PLANNED');
    const lastNotPlannedElementIndex = lastNotPlannedElement? this.saleToEdit['salesDetails'].indexOf(lastNotPlannedElement) : -1;
    const elementIndex = this.saleToEdit['salesDetails'].indexOf(element);
    return element.instalmentStatus == 'PLANNED' && !this.isInstalmentExpired(element) && lastNotPlannedElementIndex != -1 && elementIndex > lastNotPlannedElementIndex;
  }

  isSaleDataChanged() {
    return this.saleEditForm.get('instalments').value.some(item => {
      const element = this.saleToEdit['salesDetails'].find(element => element.id == item.id);
      return item.amount != element.amount;
    })
  }

  get saleEditFormMinAmount() {
    return this.minInstalmentAmount;
  }

  saleAmountValidator(saleAmount): ValidatorFn {
    return (control: FormArray): ValidationErrors => {
      return control.value.reduce((acc, item) => acc + item.amount, 0).toFixed(2) != saleAmount ?
        { notEqual: saleAmount } : null;
    };
  }

  get saleEditFormInstalmentTotalAmount() {
    return this.saleEditForm.get('instalments').value.reduce((acc, item) => acc + item.amount, 0).toFixed(2);
  }

  async openModalSaleEdit(data) {
    await this.getSalesDetails(data.idSale, data);
    this.saleToEdit = data;
    this.saleEditForm.reset();
    (this.saleEditForm.get('instalments') as UntypedFormArray).clear();
    data['salesDetails'].forEach((element) => {
      (this.saleEditForm.get('instalments') as UntypedFormArray).push(
        this.formBuilder.group({
          id: [element.id, Validators.required],
          amount: [element.amount, [Validators.required, Validators.min(this.saleEditFormMinAmount)]]
        })
      );
    });
    if (data.paymentType == 'RATEIZZATO') {
      this.saleEditForm.get('instalments').setValidators([this.saleAmountValidator(data.amount)]);
      this.saleEditForm.get('instalments').updateValueAndValidity();
    } else {
      this.saleEditForm.get('instalments').clearValidators();
      this.saleEditForm.get('instalments').updateValueAndValidity();
    }
    this.modalRef = this.modalService.show(this.saleEditModal , { class: 'modal-xl' } )
  }

  editSale() {
    swal.fire({
      icon: "warning",
      title: this.translate.instant('sale.swal.warning_edit_sale_title'),
      html: this.translate.instant('sale.swal.warning_edit_sale_text') + '<br/><br/>' +
        '<span class=\'text-danger font-weight-bold\'>' + this.translate.instant('sale.swal.warning_edit_sale_text_warning', {daysLimit: this.saleFrozenMaxDays}) + '</span>',
      confirmButtonText: this.translate.instant('sale.swal.confirm_edit_text'),
      showCancelButton : true,
      cancelButtonText: this.translate.instant('general.cancel')
    }).then((result) => {
      if( result.value ) {
        swal.showLoading();
        const id = this.saleToEdit.idSale;
        const data = { instalments: this.saleEditForm.value.instalments }
        this.salesService.editSale( id, data ).subscribe((res)=> {
          this.prepareForSearch()
          this.modalRef.hide()
        })
      } else {
        swal.close()
      }
    })
  }

  canViewEditPaymentMethod(row) {
    return row.paymentMethodType == 'card' && this.profile == 'SUPERADMIN';
  }

  openModalEditPaymentMethodLink(data) {
    this.salesService.getSalePaymentMethodEditLink( data.idSale ).subscribe((resp) => {
      if (resp.data) {
        this.showSwalEditPaymentMethodLink( resp.data )
      } else {
        swal.fire( this.translate.instant('swal.title_Error') , this.translate.instant('sale.view_edit_payment_method_link_error') , "error")
      }
    })
  }  

  showSwalEditPaymentMethodLink( data ) {
    swal.fire({
      title : this.translate.instant('sale.edit_payment_method_link'),
      html: '<em title="'+ this.translate.instant('sale.tooltip_copy_edit_payment_method_link') +'">' + data + '</em>',
      icon: 'info' ,
      allowOutsideClick : false,
      allowEscapeKey : false
    })
  }

  canSendEditPaymentMethod(row) {
    return row.paymentMethodType == 'card' &&
      (this.profile == 'SUPERADMIN' || (this.merchantDetail && this.merchantDetail.tos_accepted && this.merchantDetail.state == 'ACTIVE'));
  }

  sendEditPaymentMethodLink(row) {
    this.logger.log( 'sendEditPaymentMethodLink()' , row , 200)

    swal.fire({
      title: '',
      didOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });

    this.salesService.sendSalePaymentMethodEditMail(row.idSale).subscribe((response)=> {
      swal.close()
      console.log(' response invio mail' , response )
      if( response === 'OK' ) {
        swal.fire( this.translate.instant('sale.send_sale_change_payment_method_link_ok') ,
          this.translate.instant('sale.sended_sale_change_payment_method_link_ok' , {customer_email : row.email} ) , "success" )
      } else {
        swal.fire( "Error" , "" , "error" )
      }
    })
  }

  getInstalmentFrequencyMonths(instalmentFrequency) {
    switch (instalmentFrequency) {
      case 'MENSILE':
        return 1;
      case 'BIMESTRALE':
        return 2;
      case 'TRIMESTRALE':
        return 3;
      case 'SEMESTRALE':
        return 6;
      case 'ANNUALE':
        return 12;
      default:
        return 0;
    }
  }

  async openModalAddRecurrentInstalment(data) {
    await this.getSalesDetails(data.idSale, data);
    this.saleToAddRecurrentInstalment = data;
    const lastRecurrentInstalment = data['salesDetails'][data['salesDetails'].length - 1];
    let expectedExpiryDate = null;
    if (data.recurrentFrequency) {
      expectedExpiryDate = moment(lastRecurrentInstalment.expiryDate, 'YYYY-MM-DD').add(data.recurrentFrequency, 'days').toDate()
    } else if (data.instalmentFrequency && data.schedulingStartMonth) {
      expectedExpiryDate = moment(lastRecurrentInstalment.expiryDate, 'YYYY-MM-DD').date(data.schedulingStartMonth)
        .add(this.getInstalmentFrequencyMonths(data.instalmentFrequency), 'months').toDate()
    }
    this.recurrentInstalmentsToAdd = [{
      number: data['salesDetails'].length + 1,
      instalmentStatus: 'PLANNED',
      amount: data.amount,
      currency: data.currency,
      expiryDate: expectedExpiryDate,
      refundedAmount: 0,
      minDate: moment(lastRecurrentInstalment.expiryDate, 'YYYY-MM-DD').add(1, 'days').toDate()
    }];
    this.addRecurrentInstalmentForm.reset({
      expiryDate: expectedExpiryDate,
      expiryDay: expectedExpiryDate ? expectedExpiryDate.getDate() : null
    });
    this.modalRef = this.modalService.show(this.addRecurrentInstalmentModal , { class: 'modal-xl' } )
  }

  onNextRecurrenceExpiryDateChange(event) {
    const val = event;
    this.addRecurrentInstalmentForm.get('expiryDay').setValue(val ? val.getDate() : null);
    this.recurrentInstalmentsToAdd[0].expiryDate = val;
  }

  onNextRecurrenceExpiryDayChange(event) {
    const lastRecurrentInstalment = this.saleToAddRecurrentInstalment['salesDetails'][this.saleToAddRecurrentInstalment['salesDetails'].length - 1];
    const val = this.addRecurrentInstalmentForm.get('expiryDay').value;
    let expiryDate = null;
      if (val) {
        if (this.saleToAddRecurrentInstalment.instalmentFrequency && !this.saleToAddRecurrentInstalment.schedulingStartMonth) {
          expiryDate = moment(lastRecurrentInstalment.expiryDate, 'YYYY-MM-DD').date(val)
            .add(this.getInstalmentFrequencyMonths(this.saleToAddRecurrentInstalment.instalmentFrequency), 'months').toDate();
        }
      }
      this.addRecurrentInstalmentForm.get('expiryDate').setValue(expiryDate);
      this.recurrentInstalmentsToAdd[0].expiryDate = expiryDate;
  }

  addRecurrentInstalment() {
    swal.fire({
      title: '',
      didOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    const id = this.saleToAddRecurrentInstalment.idSale;
    const data = { expiryDate: moment(this.addRecurrentInstalmentForm.value.expiryDate).format('YYYY-MM-DD') }
    this.salesService.addRecurrentInstalment( id, data ).subscribe((res)=> {
      this.prepareForSearch()
      this.modalRef.hide()
    })
  }

  hasActionToShow( data ) {
    if( data.status == 'new' && !this.isExpired(data) && this.viewPaymentLink ) {
      return true;
    } else if ( data.status == 'new' && this.canRequestData(data) ) {
      return true;
    } else if ( data.status == 'frozen' ) {
      return true;
    } else if( (data.status == 'active' || data.status == 'frozen') &&
      (this.profile == 'SUPERADMIN' || (this.merchantDetail && this.merchantDetail.tos_accepted && this.merchantDetail.state == 'ACTIVE')) ) {
      return true;
    } else if( data.status == 'active' && this.canEditSale(data) ) {
      return true;
    } else if( data.status == 'active' && this.canViewEditPaymentMethod(data) ) {
      return true;
    } else if( data.status == 'active' && this.canSendEditPaymentMethod(data) ) {
      return true;
    } else if( (data.status == 'new' || data.status == 'active') && this.canAddRecurrentInstalment(data) ) {
      return true;
    } else {
      return false;
    }
  }

  canRefundPayment( element ) {
    return element.amount > element.refundTotalAmount && localStorage.getItem("profile") == "SUPERADMIN";
  }

  get refundFormMinAmount() {
    return this.minInstalmentAmount;
  }

  get refundFormMaxAmount() {
    return this.instalmentToBeRefunded.amount - this.instalmentToBeRefunded.refundTotalAmount - this.minInstalmentAmount;
  }

  openModalPaymentRefund(data) {
    this.instalmentToBeRefunded = data;
    this.refundForm.reset({
      type: RefundType.FULL,
      amount: null
    });
    this.refundForm.get('amount').setValidators([Validators.min(this.refundFormMinAmount), Validators.max(this.refundFormMaxAmount)]);
    this.refundForm.get('amount').updateValueAndValidity();
    this.modalRef = this.modalService.show(this.refundModalTemplate , { class: 'modal-lg' } )
  }

  onChangeRefundType() {
    this.refundForm.patchValue({
      amount: null
    });
    if (this.refundForm.get('type').value == RefundType.PARTIAL) {
      this.refundForm.get('amount').setValidators([Validators.min(this.refundFormMinAmount), Validators.max(this.refundFormMaxAmount), Validators.required]);
      this.refundForm.get('amount').updateValueAndValidity();
    } else {
      this.refundForm.get('amount').setValidators([Validators.min(this.refundFormMinAmount), Validators.max(this.refundFormMaxAmount)]);
      this.refundForm.get('amount').updateValueAndValidity();
    }
  }

  refundInstalment() {
    swal.fire({
      title: this.translate.instant('sale.swal.refund_instalment_title'), 
      text: this.translate.instant('sale.swal.refund_instalment_text'), 
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: this.translate.instant('general.back'),
      confirmButtonText: this.translate.instant('general.continue'),
    }).then((result) => {
      if( result.value ) {
        setTimeout(() => swal.showLoading(), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
        const data = { amount: this.refundForm.value.amount }
        this.appService.newElement('/payment/instalment/refund/'+this.instalmentToBeRefunded.id, data).subscribe((resp) => {
          setTimeout(() => swal.hideLoading(), 200)
          if( resp.outcome.success ) {
            swal.fire({
              icon: "success",
              text: this.translate.instant('sale.swal.refund_instalment_success')
            }).then((result) => {
              this.prepareForSearch()
              this.modalRef.hide()
            })
          } else {
            let errorMessage = null;
            switch (resp.outcome.code) {
              case "0037":
              case "0039":
              case "0040":
              case "0041":
              case "0042":
              case "0043":
              case "0052":
                errorMessage = this.settings.manageErrorMsg(resp.outcome)
                break;
              case "0038":
                swal.fire({
                  html: this.settings.manageErrorMsg(resp.outcome) + '<br><br>' + this.translate.instant('sale.swal.refund_instalment_topup_sale_text'),
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#aaa',
                  cancelButtonText: this.translate.instant('swal.lbl_btn_cancel'),
                  confirmButtonText: this.translate.instant('sale.swal.lbl_btn_confirm')
                })
                .then((result) => {
                  if( result.value ) {
                    setTimeout(() => swal.showLoading(), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
                    const topUpData = {
                      idInstalmentRef: this.instalmentToBeRefunded.id,
	                    saleAmount: data.amount
                    }
                    this.salesService.insertTopUpSale( topUpData ).subscribe((res)=> {
                      setTimeout(() => swal.hideLoading(), 200)
                      if (res.outcome.success) {
                        swal.fire({
                          icon: "success",
                          text: this.translate.instant('sale.swal.refund_instalment_topup_sale_created_success')
                        }).then((result) => {
                          this.prepareForSearch()
                          this.modalRef.hide()
                        })
                      } else {
                        swal.fire({
                          icon: "error",
                          text: this.translate.instant('sale.swal.refund_instalment_topup_sale_created_error')
                        }).then((result) => {
                          this.prepareForSearch()
                          this.modalRef.hide()
                        })
                      }
                    })
                  } else {
                    swal.close()
                  }
                })
                break;
              default:
                errorMessage = this.translate.instant('sale.swal.refund_instalment_error')
                break;
            }
            if (errorMessage) {
              swal.fire({
                icon: "error",
                text: errorMessage
              }).then((result) => {
                this.prepareForSearch()
                this.modalRef.hide()
              })
            }
          }
        })
      } else if( result.dismiss ) {
        swal.close()
      }
    })
  }

  canCompleteRefundPayment( element ) {
    return localStorage.getItem("profile") == "SUPERADMIN";
  }

  completeRefundInstalment( data ) {
    swal.fire({
      title: this.translate.instant('sale.swal.complete_refund_instalment_title'), 
      text: this.translate.instant('sale.swal.complete_refund_instalment_text'), 
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: this.translate.instant('general.back'),
      confirmButtonText: this.translate.instant('general.continue')
    }).then((result) => {
      if( result.value ) {
        setTimeout(() => swal.showLoading(), 200) // WORKAROUND -> https://github.com/sweetalert2/sweetalert2/issues/609#issue-255084207
        this.appService.editElement('/payment/instalment/refund/complete/'+data.id, null).subscribe((resp) => {
          setTimeout(() => swal.hideLoading(), 200)
          if( resp.outcome && resp.outcome.success ) {
            swal.fire({
              icon: "success",
              text: this.translate.instant('sale.swal.complete_refund_instalment_success')
            }).then((result) => {
              this.prepareForSearch()
            })
          } else {
            let errorMessage = null;
            switch (resp.code) {
              case "0039":
              case "0040":
              case "0041":
              case "0042":
                errorMessage = this.settings.manageErrorMsg(resp)
                break;
              default:
                errorMessage = this.translate.instant('sale.swal.complete_refund_instalment_error')
                break;
            }
            swal.fire({
              icon: "error",
              text: errorMessage
            }).then((result) => {
              this.prepareForSearch()
            })
          }
        })
      } else if( result.dismiss ) {
        swal.close()
      }
    })
  }

  openInfoModal() {
    this.modalRef = this.modalService.show(this.infoModalTemplate , { class: 'modal-lg' } )
  }

  saveHideInfo() {
    this.modalRef.hide();
    setTimeout(() => {
      this.hideSaleInfo = this.infoForm.value.hideInfo;
      localStorage.setItem('hideSaleInfo',JSON.stringify(this.hideSaleInfo))
    }, 200)
  }

  ngOnDestroy(){
    this.notifySelectedUserChange.unsubscribe()
  }
}
