<!--<div class="guide text-right">
  <ng-template #guideTemplate>
    <div class="guide-title">{{'sale.rate_analysis_guide_title' | translate}}</div>
    <div class="guide-text">{{'sale.rate_analysis_guide_text' | translate}}</div>
  </ng-template>
  <h3>
    <span [popover]="guideTemplate" popoverTitle="{{'sale.rate_analysis' | translate}}" [outsideClick]="true" placement="left"><i class="fa fa-info-circle"></i></span>
  </h3>
</div>-->
<div class="card">
  <div class="w100 app-search">
    <div class="w800">

      <div class="mx-4 my-3 bg-info rounded">
        <div class="d-flex align-items-center p-3">
          <div>
            <i class="fa fa-info-circle fa-2x"></i>
          </div>
          <div class="ml-3 flex-grow-1">
            <div class="guide-title">{{'sale.rate_analysis_guide_title' | translate}}</div>
            <div class="guide-text">{{'sale.rate_analysis_guide_text' | translate}}</div>
            <div class="guide-highlight mt-2" *ngIf="hideInstalmentInfo">
              <div class="guide-title">{{'sale.rate_analysis_guide_info_title' | translate}}</div>
              <div class="guide-text">{{'sale.rate_analysis_guide_info_text' | translate}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <app-advance-search 
        [intervalDropdownsPlaceholder]="'profile.interval'" [selectOptionsPlaceholder]="'profile.selectUsers'"
        [selectOptions]="selectOptions" (searchEvent)="searchEvent($event)" (deletedChip)="chipDeleted()" (intervalEvent)="intervalEvent($event)"
        [options]="options" (exclusionEvent)="onExclusionChange($event)" [expiredOptions]="expiredOptions" (changeAlert)="initRateTable('1')" [page]="'rate-analysis'"></app-advance-search> --> <!-- [intervalOptions]="intervalOptions" -->

      <!--<app-filter-search (searchEvent)="searchEvent($event)" [page]="'installment-analysis'"
        (exclusionEvent)="onExclusionChange($event)" [expiredOptions]="expiredOptions">
      </app-filter-search>-->

      <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
        <form [formGroup]="searchForm">
          <div class="row">
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerName" [label]="'general.name_nominative'" [placeholder]="'general.name_nominative'"></sp-input-text>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerSurname" [label]="'general.surname'" [placeholder]="'general.surname'"></sp-input-text>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerEmail" [label]="'general.email'" [placeholder]="'general.email'"></sp-input-text>
            <sp-input-text-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.amountFrom" [controlTo]="searchForm.controls.amountTo" [type]="'number'"
              [label]="'general.import'" [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-text-range>
            <!--<sp-input-datepicker class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangeExpiration" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_expire'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker>-->
            <!--<sp-input-datepicker-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.dateFromExpiration" [controlTo]="searchForm.controls.dateToExpiration" [showClearButton]="true" [autoApply]="false" [label]="'general.date_expire'"
              [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-datepicker-range>-->
            <sp-input-datepicker-bootstrap class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangeExpiration" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_expire'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker-bootstrap>
            <!--<sp-input-datepicker class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangeCollection" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_collection'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker>-->
            <!--<sp-input-datepicker-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.dateFromCollection" [controlTo]="searchForm.controls.dateToCollection" [showClearButton]="true" [autoApply]="false" [label]="'general.date_collection'"
              [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-datepicker-range>-->
            <!--<sp-input-datepicker class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangePayment" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_payment'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker>-->
            <!--<sp-input-datepicker-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.dateFromPayment" [controlTo]="searchForm.controls.dateToPayment" [showClearButton]="true" [autoApply]="false" [label]="'general.date_payment'"
              [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-datepicker-range>-->
            <sp-input-datepicker-bootstrap class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRangePayment" [singleDatePicker]="false" [showClearButton]="true" [label]="'general.date_payment'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker-bootstrap>
            <sp-input-instalment-status-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.status" [multiple]="true" [label]="'general.state'" [placeholder]="'advance-search.select_state'"></sp-input-instalment-status-select>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.userEmail" [label]="'general.creator_email'" [placeholder]="'general.creator_email'"></sp-input-text>
            <sp-input-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idMerchant" [label]="'general.merchant'" [placeholder]="'advance-search.select_merchant'"
              *ngIf="(profile=='SUPERADMIN' || profile=='RESELLER') && !selectedUser"></sp-input-merchant-select>
            <sp-input-external-application-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idExternal" [idMerchant]="searchForm.controls.idMerchant.value"
              [label]="'general.external_application'" [placeholder]="'advance-search.select_external_application'"></sp-input-external-application-merchant-select>
          </div>
        </form>
        <form [formGroup]="sortForm">
          <div class="row">
            <sp-input-checkbox class="col-md-4 col-sm-6 mt-2 align-self-end" [control]="sortForm.controls.viewExpired" [label]="'advance-search.includi_scaduti_rate'" [id]="'viewExpired'" (itemToggled)="onExpiredExclusionChange($event)"></sp-input-checkbox>
            <sp-input-checkbox class="col-md-4 col-sm-6 mt-2 align-self-end" [control]="sortForm.controls.viewRefunded" [label]="'advance-search.includi_rimborsati_rate'" [id]="'viewRefunded'" (itemToggled)="onRefundedExclusionChange($event)"></sp-input-checkbox>
            <div class="col-md-4 col-sm-6 mt-2 align-self-end">
              <sp-input-checkbox [control]="sortForm.controls.viewDisputed" [label]="'advance-search.includi_contestati_rate'" [id]="'viewDisputed'" [default]="sortForm.get('viewDisputed').value" (itemToggled)="onDisputedExclusionChange($event)"></sp-input-checkbox>
              <sp-input-instalment-disputed-status-select [control]="sortForm.controls.statusDisputed" [multiple]="true" [label]="'general.state'" [placeholder]="'advance-search.select_state'" *ngIf="sortForm.get('viewDisputed').value"></sp-input-instalment-disputed-status-select>
            </div>
          </div>
        </form>
      </sp-filter-section>

    </div>
  </div>
  <div class="table-margin">
    <ngx-datatable #dataTable class='material outer-table expandable data-table' [columnMode]="'force'"
      [headerHeight]="0" [footerHeight]="70" [rowHeight]="120" [scrollbarH]="true" [scrollbarV]="false" [rows]="rows" [count]="totalRows"
      *ngIf="rows.length > 0" [externalPaging]="true" [limit]="tableRecordNumbers" [offset]="tablePage - 1" (page)="onPage($event)" [messages]="my_messages">
      <ngx-datatable-row-detail #myDetailRow>
        <ng-template let-row="row" let-superRow="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="white second-table-position" *ngIf="row.rateDetails">
            <div class="row align-items-center justify-cnt">
              <div class="col-4">
                <h4 class="text-wrap" [innerHTML]="appService.formatLongText(row.rateDetails.service)"></h4>
                <div class="table-font-size">
                  <ng-container *ngIf="row.rateDetails.fullName">{{row.rateDetails.fullName}}</ng-container>
                  <ng-container *ngIf="!row.rateDetails.fullName">{{row.rateDetails.name}} {{row.rateDetails.surname}}</ng-container>
                </div>
                <div class="table-font-size">{{row.rateDetails.email}}</div>
                <div class="table-font-size text-bold" *ngIf="profile == 'SUPERADMIN' || profile == 'RESELLER'">{{row.rateDetails.merchantCompanyName}}</div>
                <div class="table-font-size text-bold font-italic">
                  <ng-container *ngIf="row.rateDetails.fromExternalApplication">{{'sale.from_external_application' | translate}}</ng-container>
                  <ng-container *ngIf="row.rateDetails.fromExternal">{{'sale.from_external' | translate}}</ng-container>
                  <ng-container *ngIf="row.rateDetails.fromManagement">{{'sale.from_management' | translate}}</ng-container>
                  <ng-container *ngIf="row.rateDetails.fromPos">{{'sale.from_pos' | translate}}</ng-container>
                  <ng-container *ngIf="!row.rateDetails.fromExternalApplication && !row.rateDetails.fromExternal && !row.rateDetails.fromManagement && !row.rateDetails.fromPos">{{'sale.from_portal' | translate}}</ng-container>
                </div>
                <div class="table-font-size secondary-text-color" *ngIf="row.rateDetails.userEmail">
                  {{row.rateDetails.userEmail}} <div class="user-info dark-bg a-center m-0 ml-1">{{ 'user.profile_role.'+row.rateDetails.userProfile | translate }}</div>
                </div>
                <div class="table-font-size secondary-text-color text-truncate" *ngIf="row.rateDetails.externalApplicationName">
                  <ng-container *ngIf="row.rateDetails.externalDescription"><span class="text-bold">{{row.rateDetails.externalDescription}}</span> -</ng-container>
                  {{row.rateDetails.externalApplicationName}}
                </div>
              </div>
              <div class="col-2">
                <div class="row align-items-center justify-content-center">
                  <div class="col-12">
                    <div class="d-flex align-items-center justify-content-center">
                      <div>
                        <div *ngIf="row.rateDetails.status == 'new' && isSaleDetailExpired(row.rateDetails)" class="status-div status-div-table my-0" [class.expired]="isSaleDetailExpired(row.rateDetails)">{{ 'sale.sale_status.expired' | translate }}</div>
                        <div *ngIf="row.rateDetails.status == 'new' && !isSaleDetailExpired(row.rateDetails)" class="status-div status-div-table my-0 {{row.rateDetails.status}}">{{ 'sale.sale_status.'+row.rateDetails.status | translate }}</div>
                        <div *ngIf="row.rateDetails.status != 'active' && row.rateDetails.status != 'new'" class="status-div status-div-table my-0 {{row.rateDetails.status}}">{{ 'sale.sale_status.'+row.rateDetails.status | translate }}</div>
                        <div *ngIf="row.rateDetails.status == 'active'" class="active_status status-div status-div-table my-0">{{ 'sale.sale_status.'+row.rateDetails.status | translate }}</div>
                      </div>
                      <!-- <div class="status-pencil">
                        <app-status-select [allStatus]="allStatus" (hideSelectEvent)="hideSelectEvent(row)"
                          (statusSelectedEvent)="statusSelectedEvent($event,row)"></app-status-select>
                      </div> -->
                      <div class="d-inline-block ml-1" style="line-height: 1.2;" *ngIf="row.rateDetails.status == 'frozen' || isSaleRefundPending(row.rateDetails)">
                        <ng-template #saleStatusPopoverTemplate>
                          <ng-container *ngIf="row.rateDetails.status == 'frozen'">{{ 'sale.sale_frozen_expiration_detail_popover_desc' | translate: {days_expiration: getSaleFrozenExpirationDays(row.rateDetails)} }}</ng-container>
                          <ng-container *ngIf="row.rateDetails.status == 'frozen' && isSaleRefundPending(row.rateDetails)"><hr class="my-2"></ng-container>
                          <ng-container *ngIf="isSaleRefundPending(row.rateDetails)">{{ 'sale.refund_pending_detail_popover_desc' | translate }}</ng-container>
                        </ng-template>
                        <i class="fa fa-exclamation-circle info-icon" placement="right" container="body" [popover]="saleStatusPopoverTemplate" popoverTitle="{{ 'sale.sale_status_detail_popover_title' | translate }}" [outsideClick]="true"></i>
                        <!--<i class="fa fa-exclamation-circle info-icon" placement="right" container="body" popover="{{ 'sale.refund_pending_detail_popover_desc' | translate }}" popoverTitle="{{ 'sale.refund_pending_detail_popover_title' | translate }}" [outsideClick]="true"></i>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="getSaleRefundStatus(row.rateDetails)">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="user-info user-info-block {{getSaleRefundStatus(row.rateDetails)}} m-0">{{'sale.sale_status.'+getSaleRefundStatus(row.rateDetails)|translate}}</div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="row.rateDetails.paymentMethodType">
                    <div class="d-flex align-items-center justify-content-center">
                      <img class="payment-icon" *ngIf="(row.rateDetails.paymentMethodType == 'card' || row.rateDetails.paymentMethodType == 'card_present') && row.rateDetails.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                        title="{{'sale.payment_method_last4' | translate}} {{row.rateDetails.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.rateDetails.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.rateDetails.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon" *ngIf="(row.rateDetails.paymentMethodType == 'card' || row.rateDetails.paymentMethodType == 'card_present') && row.rateDetails.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                        title="{{'sale.payment_method_last4' | translate}} {{row.rateDetails.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.rateDetails.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.rateDetails.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon" *ngIf="(row.rateDetails.paymentMethodType == 'card' || row.rateDetails.paymentMethodType == 'card_present') && row.rateDetails.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                        title="{{'sale.payment_method_last4' | translate}} {{row.rateDetails.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.rateDetails.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.rateDetails.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon" *ngIf="(row.rateDetails.paymentMethodType == 'card' || row.rateDetails.paymentMethodType == 'card_present') && row.rateDetails.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.rateDetails.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                        row.rateDetails.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                        title="{{row.rateDetails.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.rateDetails.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.rateDetails.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.rateDetails.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon" *ngIf="row.rateDetails.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.rateDetails.paymentMethodLast4 || '----'}}">
                      <img class="payment-icon" *ngIf="row.rateDetails.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
                      <img class="payment-icon" *ngIf="row.rateDetails.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
                      <img class="payment-country-icon ml-1" *ngIf="row.rateDetails.paymentMethodCountry" src="../../../../assets/img/country/{{row.rateDetails.paymentMethodCountry}}.png">
                      <ng-template #salePaymentMethodPopoverTemplate>
                        <ng-container *ngIf="isSalePaymentMethodGoingToExpire(row.rateDetails)">{{ 'sale.payment_method_going_to_expire_popover_desc' | translate }}</ng-container>
                        <ng-container *ngIf="isSalePaymentMethodExpired(row.rateDetails)">{{ 'sale.payment_method_expired_popover_desc' | translate }}</ng-container>
                      </ng-template>
                      <i class="fa fa-exclamation-circle info-icon ml-1" *ngIf="isSalePaymentMethodGoingToExpire(row.rateDetails) || isSalePaymentMethodExpired(row.rateDetails)" placement="right" container="body" [popover]="salePaymentMethodPopoverTemplate" popoverTitle="{{ 'sale.payment_method_going_to_expire_popover_title' | translate }}" [outsideClick]="true"></i>
                    </div>
                  </div>
                </div>
              </div>

              <!--<div class="col-1">
                <div class="border-left-style" style="padding-left: 10px;">
                  <h4>&nbsp;</h4>
                  <div>&nbsp;</div>
                </div>
              </div>-->

              <div class="col-2">
                <div class="row align-items-center justify-content-center a-center border-left-style">
                  <div class="col-12">
                    <h4>{{row.rateDetails.amount}} <span style="font-size: 12px;">{{row.rateDetails.currency}}</span></h4>
                    <div class="secondary-text-color">{{'sale.total_amount' | translate}}</div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <h4 class="text-wrap" *ngIf="row.rateDetails.paymentType=='RATEIZZATO'">{{'sale.type_in_installments' | translate}}</h4>
                <h4 class="text-wrap" *ngIf="row.rateDetails.paymentType=='RICORRENTE'">{{'sale.type_recurrent' | translate}}</h4>
                <h4 class="text-wrap" *ngIf="row.rateDetails.paymentType=='SINGOLO'">{{'sale.type_single' | translate}}</h4>
                <h4 class="text-wrap" *ngIf="row.rateDetails.paymentType=='VARIABLE'">{{'sale.type_variable' | translate}}</h4>
                <h4 class="text-wrap" *ngIf="row.rateDetails.paymentType=='INSTANT'">{{'sale.type_instant' | translate}}</h4>
                <h4 class="text-wrap" *ngIf="row.rateDetails.paymentType=='RECHARGE_EXT_REFUND'">{{'sale.type_topup_refund' | translate}}</h4>
                <h4 class="text-wrap" *ngIf="row.rateDetails.paymentType=='CHARGE_DISPUTE'">{{'sale.type_charge_dispute' | translate}}</h4>
                <ng-container *ngIf="row.rateDetails.paymentType=='RATEIZZATO'">
                  <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.rateDetails.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
                  <div class="table-font-size">{{'sale.expiration_first_installment_on' | translate}} {{row.rateDetails.startDate | date:'dd/MM/yyyy'}}</div>
                  <div class="table-font-size">
                    {{'sale.installment_from' | translate}} {{row.rateDetails.startDate | date:'dd/MM/yyyy'}} {{'sale.installment_to' | translate}} {{row.rateDetails.endDate | date:'dd/MM/yyyy'}} 
                  </div>
                  <div class="table-font-size">
                    <ng-container [ngSwitch]="row.rateDetails.instalmentFrequency">
                      <ng-container *ngSwitchCase="'MENSILE'">{{'sale.installment_frequency_monthly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.installment_frequency_bimonthly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.installment_frequency_quarterly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.installment_frequency_half_yearly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.installment_frequency_yearly' | translate}}</ng-container>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="row.rateDetails.paymentType=='RICORRENTE'">
                  <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.rateDetails.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
                  <div class="table-font-size">{{'sale.expiration_on' | translate}} {{row.rateDetails.startDate | date:'dd/MM/yyyy'}}</div>
                  <div class="table-font-size">
                    <ng-container [ngSwitch]="row.rateDetails.instalmentFrequency">
                      <ng-container *ngSwitchCase="'MENSILE'">{{'sale.recurrence_monthly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.recurrence_bimonthly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.recurrence_quarterly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.recurrence_half_yearly' | translate}}</ng-container>
                      <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.recurrence_yearly' | translate}}</ng-container>
                      <ng-container *ngSwitchDefault>
                        <ng-container *ngIf="row.rateDetails.recurrentFrequency">{{'sale.recurrence_days' | translate: {'days': row.rateDetails.recurrentFrequency} }}</ng-container>
                        <ng-container *ngIf="!row.rateDetails.recurrentFrequency">{{'sale.recurrence_manual' | translate}}</ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="row.rateDetails.paymentType=='SINGOLO' || row.rateDetails.paymentType=='INSTANT' || row.rateDetails.paymentType=='RECHARGE_EXT_REFUND' || row.rateDetails.paymentType=='CHARGE_DISPUTE'">
                  <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.rateDetails.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
                  <div class="table-font-size">{{'sale.expiration_on' | translate}} {{row.rateDetails.startDate | date:'dd/MM/yyyy'}}</div>
                </ng-container>
                <ng-container *ngIf="row.rateDetails.paymentType=='VARIABLE'">
                  <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.rateDetails.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
                  <div class="table-font-size">{{'sale.first_expiration_on' | translate}} {{row.rateDetails.startDate | date:'dd/MM/yyyy'}}</div>
                  <div class="table-font-size">{{'sale.last_expiration_on' | translate}} {{row.rateDetails.endDate | date:'dd/MM/yyyy'}}</div>
                </ng-container>
                <!--<ng-container *ngIf="row.rateDetails.paymentType=='RATEIZZATO'">
                  <h4>{{row.rateDetails.instalmentNumber}}</h4>
                  <div class="secondary-text-color">{{'sale.rate' | translate}}</div>
                </ng-container>-->
              </div>
              <!-- <div class="col-3">
                <div class="row float-right pad-r">
                  <button (click)="redirectToEdit(row)" class="blue-bg details-button">
                    <span>{{'sale.modificate' | translate}}</span>
                  </button>
                </div>
              </div> -->
            </div>
            <!-- <ng-container *ngIf="row.rateDetails.paymentType !='SINGOLO'"> -->
              <div class="row col-md-12 rate-inner-table-title in-d">{{'sale.rate_details' | translate}}</div>
              <div class="table-wrapper rate-inner-table inner-table-rate">
                <ngx-datatable #table id="{{row.id}}" class='material expandable data-table' [columnMode]="'force'" [headerHeight]="50"
                  [footerHeight]="50" [rowHeight]="70" [scrollbarH]="true" [scrollbarV]="false" [rows]="row.salesInstallments"
                  [limit]="tableRecordNumbers" [messages]="my_messages">
                  <ngx-datatable-row-detail #mySubDetailRow>
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                      <div class="gray third-table-position" *ngIf="row.instalmentRefs && row.instalmentRefs.length">
                        <div class="rate-inner-table-title">{{'sale.refund_debit_details' | translate}}</div>
                        <div class="table-wrapper rate-inner-table inner-table-rate">
                          <ngx-datatable #subTable class='material expandable data-table' [columnMode]="'force'" [headerHeight]="50"
                          [footerHeight]="50" [rowHeight]="70" [scrollbarH]="false" [scrollbarV]="false" [rows]="row.instalmentRefs"
                          [limit]="tableRecordNumbers" [messages]="my_messages">
                            <ngx-datatable-column name="N° / {{'sale.state' | translate}}" [width]="230" [resizeable]="false" [sortable]="false" [draggable]="false">
                              <ng-template ngx-datatable-header-template>
                                <div class="row">
                                  <div class="col-3">
                                    N°
                                  </div>
                                  <div class="col-9">
                                    {{'sale.state' | translate}}
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <div class="row align-items-centers">
                                  <div class="col-3">
                                    <div>{{rowIndex + 1}}</div>
                                  </div>
                                  <div class="col-9">
                                    <div>
                                      <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{ 'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
                                      <div class="mt-1" *ngIf="( row.paymentType != 'RECHARGE_EXT_REFUND' && row.instalmentStatus == 'PENDING_REFUND' && canCompleteRefundPayment(row) )">
                                        <button class="blue-bg details-button" (click)="completeRefundInstalment(row)">
                                          <span>{{'sale.label_complete_refund' | translate}}</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="100" [resizeable]="false" [sortable]="false" [draggable]="false">
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <div>{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></div>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'sale.request' | translate}} / {{'sale.refunded' | translate}} / {{'sale.debited' | translate}}" [width]="400" [resizeable]="false" [sortable]="false" [draggable]="false">
                              <ng-template ngx-datatable-header-template>
                                <div class="row">
                                  <div class="col-5">
                                    {{'sale.request' | translate}}
                                  </div>
                                  <div class="col-7">
                                    {{'sale.refunded' | translate}} / {{'sale.debited' | translate}}
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <ng-container *ngIf="row.expiryDate">{{row.expiryDate | date:'dd/MM/yyyy'}}</ng-container>
                                  </div>
                                  <div class="col-7">
                                    <div class="text-wrap">
                                      <ng-container *ngIf="row.lastTransaction">
                                        <ng-container *ngIf="row.instalmentStatus == 'REFUNDED' || row.instalmentStatus == 'PAID_LOST_DISPUTE' || row.instalmentStatus == 'PAID_WON_DISPUTE' || row.instalmentStatus == 'REFUNDED_DISPUTE'">
                                          {{row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'}}
                                        </ng-container>
                                        <div class="font-size-style text-danger" *ngIf="row.instalmentStatus == 'ERROR_REFUND'">
                                          {{ 'sale.instalment_refund_error' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss',
                                            result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}
                                        </div>
                                        <div class="font-size-style text-warning" *ngIf="row.instalmentStatus == 'PROCESSING_REFUND'">
                                          {{ 'sale.instalment_refund_processing' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'} }}
                                        </div>
                                        <ng-container *ngIf="row.instalmentStatus != 'REFUNDED' && row.instalmentStatus != 'ERROR_REFUND' && row.instalmentStatus != 'PROCESSING_REFUND' && row.instalmentStatus != 'PAID_LOST_DISPUTE'
                                           && row.instalmentStatus != 'PAID_WON_DISPUTE' && row.instalmentStatus != 'REFUNDED_DISPUTE'">-</ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="!row.lastTransaction">-</ng-container>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                          </ngx-datatable>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-row-detail>

                  <ngx-datatable-column name="N°" [width]="60" [resizeable]="false" [sortable]="false" [draggable]="false">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                      <div [ngClass]="{'active-plan':  superRow.id == row.id}">{{rowIndex + 1}}</div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{'sale.state' | translate}}" [width]="170" [resizeable]="false" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div [ngClass]="{'active-plan':  superRow.id == row.id}">
                        <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{ 'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="100" [resizeable]="false" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div [ngClass]="{'active-plan':  superRow.id == row.id}">{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{'sale.expiry' | translate}}" [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div [ngClass]="{'active-plan':  superRow.id == row.id}">
                        <ng-container *ngIf="row.expiryDate">{{row.expiryDate | date:'dd/MM/yyyy'}}</ng-container>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{'sale.collected' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div [ngClass]="{'active-plan':  superRow.id == row.id}" class="text-wrap">
                        <ng-container *ngIf="row.lastTransaction">
                          <ng-container *ngIf="row.instalmentStatus == 'PAID' || row.instalmentStatus == 'DISPUTE' || row.instalmentStatus == 'DISPUTED' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY'">
                            <div class="d-flex align-items-center">
                              {{row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'}}
                              <ng-container *ngIf="row.paymentMethodType">
                                <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                                  title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                                <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                                  title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                                <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                                  title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                                <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                                  row.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                                  title="{{row.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                                <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}}">
                                <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
                                <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
                                <img class="payment-country-icon ml-1" *ngIf="row.paymentMethodCountry" src="../../../../assets/img/country/{{row.paymentMethodCountry}}.png">
                              </ng-container>
                            </div>
                          </ng-container>
                          <div class="font-size-style text-danger" *ngIf="row.instalmentStatus == 'ERROR'">
                            {{ 'sale.instalment_payment_error' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss',
                              result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}
                          </div>
                          <div class="font-size-style text-warning" *ngIf="row.instalmentStatus == 'PROCESSING'">
                            {{ 'sale.instalment_payment_processing' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'} }}
                          </div>
                          <ng-container *ngIf="row.instalmentStatus != 'PAID' && row.instalmentStatus != 'ERROR' && row.instalmentStatus != 'PROCESSING' && row.instalmentStatus != 'DISPUTE' && row.instalmentStatus != 'DISPUTED' && row.instalmentStatus != 'ONGOING_INQUIRY' && row.instalmentStatus != 'CLOSED_INQUIRY'">-</ng-container>
                        </ng-container>
                        <ng-container *ngIf="!row.lastTransaction">-</ng-container>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{'sale.payment_id' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div [ngClass]="{'active-plan':  superRow.id == row.id}">
                        <div class="font-size-style">{{row.instalmentPaymentId}}</div>
                        <div class="font-size-style">{{row.salePaymentId}}</div>
                        <div class="font-size-style" *ngIf="row.paymentId">{{row.paymentId}}</div>
                        <div class="font-size-style" *ngIf="row.payoutPaymentId">{{row.payoutPaymentId}}</div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <!-- <ngx-datatable-footer *ngIf="true">
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" >
                      
                      <div *ngIf="rowCount > 0">
                        {{ rowCount }} {{ 'datatable_message.lista_totali_righe' | translate }}
                      </div>
                      <div *ngIf="rowCount == 0">
                        {{ 'datatable_message.lista_vuota_righe' | translate }}
                      </div>
                      <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!((rowCount / pageSize) > 1)"
                        (change)="table.onFooterPage($event)">
                      </datatable-pager>
                    </ng-template>
                  </ngx-datatable-footer> -->

                </ngx-datatable>
              </div>
            <!-- </ng-container> -->
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column [width]="120">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div>
            <h4>{{row.amount}} <span style="font-size: 12px;">{{row.currency}}</span></h4>
            <div class="table-font-size">{{'sale.rate_import' | translate}}</div>
            <div class="d-flex align-items-center">
              <div class="user-info user-info-block {{getInstalmentStatus(row)}} mx-0 mt-1">{{ 'sale.rate_detail_status.'+getInstalmentStatus(row) | translate }}</div>
              <div class="d-inline-block ml-1" style="line-height: 1.2;" *ngIf="isRefundPending(row)">
                <i class="fa fa-exclamation-circle info-icon" placement="right" container="body" popover="{{ 'sale.instalment_refund_pending_detail_popover_desc' | translate }}" popoverTitle="{{ 'sale.instalment_refund_pending_detail_popover_title' | translate }}" [outsideClick]="true"></i>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="110">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <h4>{{row.expiryDate | date: 'dd/MM/yyyy'}}</h4>
            <div class="table-font-size">{{'sale.deadline_date' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="110">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <ng-container *ngIf="row.instalmentStatus != 'ERROR' && row.instalmentStatus != 'CANCELED' && row.instalmentStatus != 'PLANNED'">
              <h4 class="mb-0">{{row.tsPayment | date: 'dd/MM/yyyy'}}</h4>
              <h4 class="mb-1">{{row.tsPayment | date: 'HH:mm:ss'}}</h4>
            </ng-container>
            <!-- <h4 *ngIf="row.instalmentStatus == 'ERROR' || row.instalmentStatus == 'CANCELED'">-</h4> -->
            <h4 *ngIf="(row.instalmentStatus == 'PLANNED' || row.instalmentStatus == 'ERROR') && row.lastTransaction"><i class="fa fa-exclamation-circle" placement="right"
              popover="{{ 'sale.rata_detail_popover_desc' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss' ,
                result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}"
              popoverTitle="{{ 'sale.rate_detail_popover_title' | translate }}" [outsideClick]="true"></i></h4> <!--  style="color: #ff902b;" -->
            <h4 *ngIf="row.instalmentStatus == 'CANCELED' || !row.lastTransaction">-</h4>
            <div class="table-font-size">{{'sale.collected_date' | translate}}</div>
            <div *ngIf="(row.instalmentStatus != 'ERROR' && row.instalmentStatus != 'CANCELED' && row.instalmentStatus != 'PLANNED') && row.paymentMethodType">
              <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                row.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                title="{{row.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="row.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}}">
              <img class="payment-icon" *ngIf="row.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
              <img class="payment-icon" *ngIf="row.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
              <img class="payment-country-icon ml-1" *ngIf="row.paymentMethodCountry" src="../../../../assets/img/country/{{row.paymentMethodCountry}}.png">
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="220">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="border-left-style pl-4">
            <h4 class="text-clamp" title="{{row.service}}">{{row.service}}</h4>
            <div class="table-font-size">
              <ng-container *ngIf="row.fullName">{{row.fullName}}</ng-container>
              <ng-container *ngIf="!row.fullName">{{row.name}} {{row.surname}}</ng-container>
            </div>
            <!-- <div class="table-font-size " *ngIf="row.paymentType=='RATEIZZATO'">{{'general.valid_from' | translate}}
              {{row.startDate}} {{'general.valid_to' | translate}} {{row.endDate}} </div>
            <div class="table-font-size " *ngIf="row.paymentType=='RICORRENTE'">{{'sale.cadenza' | translate}}
              {{row.startDate}}</div>
            <div class="table-font-size " *ngIf="row.paymentType=='SINGOLO'">{{row.startDate}}</div> -->
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="110">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <h4>{{row.saleDate | date: 'dd/MM/yyyy'}}</h4>
            <div class="table-font-size">{{'sale.sales_date' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="100">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <h4>{{row.saleAmount}} <span style="font-size: 12px;">{{row.currency}}</span></h4>
            <div class="table-font-size">{{'sale.total_amount' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="130">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <div *ngIf="row.saleStatus == 'NEW' && isSaleExpired(row)" class="status-div status-div-table m-0" [class.expired]="isSaleExpired(row)">{{ 'sale.sale_status.expired' | translate }}</div>
            <div *ngIf="row.saleStatus != 'NEW' || !isSaleExpired(row)" class="status-div status-div-table {{row.saleStatus}} m-0">{{ 'sale.sale_status.'+row.saleStatus | lowercase | translate }}</div>
            <div class="table-font-size">{{ 'sale.sales_state' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <div class="f-right">
            <button class="blue-bg details-button" (click)="toggleExpandRow(row,expanded)" [disabled]="!canViewDetails(row)">
              <span *ngIf="!expanded">{{'sale.sales_det' | translate}}</span>
              <span *ngIf="expanded">{{'general.close' | translate}}</span>
            </button>
            <a href="#" class="moredd dropdown-toggle nav-link blue-bg sec-butt" data-bs-toggle="dropdown" role="button"
              aria-expanded="false" style=" text-decoration: none;">
              <span class="caret"></span>
            </a>
            <ul class="dropdown-menu detail-dropdrown" role="menu">
              <li class="nav-item" *ngIf="( row.instalmentStatus == 'PLANNED' && ( row.saleStatus == 'NEW' || row.saleStatus == 'ACTIVE' ) ) && viewPaymentLink">
                <a class="nav-link edit action_enabled" [class.action_enabled]="viewPaymentLink" (click)="showLinkPayment(row)">{{'sale.label_generate_payment_link' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="( row.instalmentStatus == 'ERROR' || row.instalmentStatus == 'UNSOLVED' ) && canSendReminder(row) && viewPaymentLink">
                <a class="nav-link edit action_enabled" [class.action_enabled]="canSendReminder(row) && viewPaymentLink" (click)="sendReminder(row)">{{'sale.label_remember_payment' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="( row.instalmentStatus == 'ERROR' && ( row.saleStatus == 'NEW' || row.saleStatus == 'ACTIVE' ) ) && (profile == 'SUPERADMIN' || (merchantDetail && merchantDetail.tos_accepted && merchantDetail.state == 'ACTIVE'))">
                <a class="nav-link edit action_enabled" (click)="openModalPaymentLink( row )">{{'sale.label_show_payment_link' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="( ( row.instalmentStatus == 'PLANNED' || row.instalmentStatus == 'ERROR' ) && ( row.saleStatus == 'NEW' || row.saleStatus == 'ACTIVE' ) ) && canRequestData(row)">
                <a class="nav-link edit action_enabled" [class.action_enabled]="canRequestData(row)" (click)="openModalSaleData(row)">{{'sale.retrieve_data' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="row.closable && (profile == 'SUPERADMIN' || (merchantDetail && merchantDetail.tos_accepted && merchantDetail.state == 'ACTIVE'))">
                <a class="nav-link edit action_enabled" (click)="closeInstalment( row )">{{'sale.label_close_instalment' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="( row.paymentType != 'RECHARGE_EXT_REFUND' && (row.instalmentStatus == 'PAID' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY') && canRefundPayment(row) )">
                <a class="nav-link edit action_enabled" [class.action_enabled]="canRefundPayment(row)" (click)="openModalPaymentRefund( row )">{{'sale.label_refund_payment' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="!hasActionToShow(row)">
                <a class="nav-link edit action_disabled">{{'general.label_empty_options' | translate}}</a>
              </li>
            </ul>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- <ngx-datatable-footer *ngIf="true">
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" >
          <div *ngIf="rowCount > 0" style="padding-left: 20px;">
            {{ rowCount }} {{ 'datatable_message.lista_totali_righe' | translate }}
          </div>
          <div *ngIf="rowCount == 0" style="padding-left: 20px;">
            {{ 'datatable_message.lista_vuota_righe' | translate }}
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!((rowCount / pageSize) > 1)"
            (change)="table.onFooterPage($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer> -->

    </ngx-datatable>
    <div class="" *ngIf="rows.length == 0">
      <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
    </div>
  </div>
</div>
<div class="export-button">
  <button class="blue-bg details-button export-button-padding" (click)="export()">
    <span>{{'sale.export' | translate}}</span>
  </button>
</div>

<ng-template #instalmentDataModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'sale.retrieve_data_title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div>{{ 'sale.retrieve_data_text' | translate }}</div>
        <div class="text-center" *ngIf="instalmentToRetrieveData">
          <qr-code [value]="instalmentToRetrieveData.token" [size]="200"></qr-code>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #refundModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'sale.refund_payment_title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        {{ 'sale.instalment_amount' | translate }}
      </div>
      <div class="col-md-6">
        {{ 'sale.instalment_total_refund_amount' | translate }} <i class="fa fa-exclamation-circle info-icon" placement="bottom" container="body" popover="{{ 'sale.instalment_total_refund_amount_detail_popover_desc' | translate }}" popoverTitle="{{ 'sale.instalment_total_refund_amount_detail_popover_title' | translate }}" [outsideClick]="true"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 highlighted-text">
        {{ instalmentToBeRefunded.amount | number:".2-2":language }} €
      </div>
      <div class="col-md-6 highlighted-text">
        {{ instalmentToBeRefunded.refundTotalAmount | number:".2-2":language }} €
      </div>
    </div>
    <form [formGroup]="refundForm">
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="label custom-label">{{'sale.refund_type' | translate }}</label>
          <div class="d-flex align-items-center">
            <div class="radio c-radio d-flex">
              <label class="mb-0" for="typeFull">
                <input type="radio" name="type" id="typeFull" formControlName="type" [value]="RefundType.FULL" (change)="onChangeRefundType()">
                <span class="fa fa-circle"></span>{{'sale.refund_type_full' | translate}}
              </label>
            </div>
            <div class="radio c-radio d-flex ml-1">
              <label class="mb-0" for="typePartial">
                <input type="radio" name="type" id="typePartial" formControlName="type" [value]="RefundType.PARTIAL" (change)="onChangeRefundType()">
                <span class="fa fa-circle"></span>{{'sale.refund_type_partial' | translate}}
              </label>
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-md-6" *ngIf="refundForm.get('type').value == RefundType.PARTIAL">
          <div class="has-float-label fl">
            <input class="custom-float-label-input" type="number" step="0.1" placeholder=" " formControlName="amount" id="amount">
            <label class="label custom-label" for="amount"> {{'sale.refund_amount' | translate}} </label>
          </div>
          <div class="text-danger" *ngIf="refundForm.get('amount').touched && refundForm.get('amount').invalid && (refundForm.get('amount').errors.min || refundForm.get('amount').errors.max)">
            <ng-container *ngIf="refundForm.get('amount').errors.min">{{ 'sale.refund_amount_min_error' | translate: {amount: (refundFormMinAmount | number:".2-2":language) + ' €'} }}</ng-container>
            <ng-container *ngIf="refundForm.get('amount').errors.max">{{ 'sale.refund_amount_max_error' | translate: {amount: (refundFormMaxAmount | number:".2-2":language) + ' €'} }}</ng-container>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mt-2">
        <div class="col-md-6">
          <button type="button" class="btn btn-block btn-primary" [disabled]="refundForm.invalid" (click)="refundInstalment()">
            {{'sale.refund_payment' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #infoModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <i class="fa fa-info-circle"></i> {{ 'sale.info_title' | translate }}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>{{ 'sale.instalment_info_message' | translate }}</div>
    <form [formGroup]="infoForm">
      <div class="row mt-3">
        <div class="col-12">
          <div class="checkbox c-checkbox d-flex">
            <label class="mb-0" for="infoShow">
              <input type="checkbox" id="infoShow" formControlName="hideInfo">
              <span class="fa fa-check check-icon-size"></span>{{'sale.not_show_anymore' | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mt-2">
        <div class="col-md-6">
          <button type="button" class="btn btn-block btn-primary" [disabled]="!infoForm.valid" (click)="saveHideInfo()">
            {{'sale.confirm_choice' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>