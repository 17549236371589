<!--<div class="guide text-right">
  <ng-template #guideTemplate>
    <div class="guide-title">{{'sale.sales_analysis_guide_title' | translate}}</div>
    <div class="guide-text">{{'sale.sales_analysis_guide_text' | translate}}</div>
  </ng-template>
  <h3>
    <span [popover]="guideTemplate" popoverTitle="{{'sale.sales_analysis' | translate}}" [outsideClick]="true" placement="left"><i class="fa fa-info-circle"></i></span>
  </h3>
</div>-->
<div class="card">
  <div class="w100 app-search">
    <div class="w800">

      <div class="mx-4 my-3 bg-info rounded">
        <div class="d-flex align-items-center p-3">
          <div>
            <i class="fa fa-info-circle fa-2x"></i>
          </div>
          <div class="ml-3 flex-grow-1">
            <div class="guide-title">{{'sale.sales_analysis_guide_title' | translate}}</div>
            <div class="guide-text">{{'sale.sales_analysis_guide_text' | translate}}</div>
            <div class="guide-highlight mt-2" *ngIf="hideSaleInfo">
              <div class="guide-title">{{'sale.sales_analysis_guide_info_title' | translate}}</div>
              <div class="guide-text">{{'sale.sales_analysis_guide_info_text' | translate}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <app-advance-search [sortOptions]="sortOptions" [sortOptionsPlaceholder]="'profile.sort'"
        [selectOptionsPlaceholder]="'profile.selectUsers'" (searchEvent)="searchEvent($event)" (deletedChip)="chipDeleted()"
        (sortEvent)="sortEvent($event)" [options]="options" (changeAlert)="initSaleTable('1')" [expiredOptions]="expiredOptions" (exclusionEvent)="onExclusionChange($event)" [page]="'new-sale'">
      </app-advance-search> -->

      <!--<app-filter-search (searchEvent)="searchEvent($event)" (sortEvent)="sortEvent($event)"
        [page]="'sale-analysis'" [sortOptions]="sortOptions" [sortOptionsPlaceholder]="'profile.sort'"
        [expiredOptions]="expiredOptions" (exclusionEvent)="onExclusionChange($event)">
      </app-filter-search>-->

      <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
        <form [formGroup]="searchForm">
          <div class="row">
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerName" [label]="'general.name_nominative'" [placeholder]="'general.name_nominative'"></sp-input-text>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerSurname" [label]="'general.surname'" [placeholder]="'general.surname'"></sp-input-text>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.customerEmail" [label]="'general.email'" [placeholder]="'general.email'"></sp-input-text>
            <sp-input-text-range class="col-md-4 col-sm-6 mt-2" [controlFrom]="searchForm.controls.amountFrom" [controlTo]="searchForm.controls.amountTo" [type]="'number'"
              [label]="'general.import'" [placeholderFrom]="'advance-search.f_import'" [placeholderTo]="'advance-search.s_import'"></sp-input-text-range>
            <sp-input-datepicker-bootstrap class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.dateRange" [singleDatePicker]="false" [label]="'general.date_created'" [placeholder]="'advance-search.date_input'"></sp-input-datepicker-bootstrap>
            <sp-input-sale-status-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.status" [multiple]="true" [label]="'general.state'" [placeholder]="'advance-search.select_state'"></sp-input-sale-status-select>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.userEmail" [label]="'general.creator_email'" [placeholder]="'general.creator_email'"></sp-input-text>
            <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.service" [label]="'general.services'" [placeholder]="'general.services'"></sp-input-text>
            <sp-input-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idMerchant" [label]="'general.merchant'" [placeholder]="'advance-search.select_merchant'"
              *ngIf="(profile=='SUPERADMIN' || profile=='RESELLER') && !selectedUser"></sp-input-merchant-select>
            <sp-input-external-application-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idExternal" [idMerchant]="searchForm.controls.idMerchant.value"
              [label]="'general.external_application'" [placeholder]="'advance-search.select_external_application'"></sp-input-external-application-merchant-select>
          </div>
        </form>
        <form [formGroup]="sortForm">
          <div class="row">
            <sp-input-checkbox class="col-md-4 col-sm-6 mt-2 align-self-end" [control]="sortForm.controls.viewExpired" [label]="'advance-search.includi_scaduti_vendite'" [id]="'viewExpired'" (itemToggled)="onExpiredExclusionChange($event)"></sp-input-checkbox>
            <sp-input-checkbox class="col-md-4 col-sm-6 mt-2 align-self-end" [control]="sortForm.controls.viewRefund" [label]="'advance-search.includi_rimborsi_vendite'" [id]="'viewRefund'" (itemToggled)="onRefundExclusionChange($event)"></sp-input-checkbox>
            <sp-input-checkbox class="col-md-4 col-sm-6 mt-2 align-self-end" [control]="sortForm.controls.viewDispute" [label]="'advance-search.includi_contestazioni_vendite'" [id]="'viewDispute'" (itemToggled)="onDisputeExclusionChange($event)"></sp-input-checkbox>
            <sp-input-sorting-select class="col-md-4 col-sm-6 mt-2" [control]="sortForm.controls.sorting" [sortings]="sortings" (itemSelected)="onSortingChange($event)"></sp-input-sorting-select>
          </div>
        </form>
      </sp-filter-section>

    </div>
  </div>
  <div class="table-margin">
    <ngx-datatable #dataTable class='material outer-table expandable data-table' [columnMode]="'force'"
      [headerHeight]="0" [footerHeight]="70" [rowHeight]="profile == 'SUPERADMIN' || profile == 'RESELLER' ? 150 : 130" [scrollbarH]="true" [scrollbarV]="false" [rows]="rows" [count]="totalRows"
      *ngIf="rows.length > 0" [externalPaging]="true" [limit]="tableRecordNumbers" [offset]="tablePage - 1" (page)="onPage($event)" [messages]="my_messages">
      <ngx-datatable-row-detail #myDetailRow>
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="white second-table-position" *ngIf="row.salesDetails">
            <div class="row col-md-12 inner-table-title">
              <ng-container *ngIf="row.paymentType != 'CHARGE_DISPUTE'">{{'sale.rate_details' | translate}}</ng-container>
              <ng-container *ngIf="row.paymentType == 'CHARGE_DISPUTE'">{{'sale.debit_details' | translate}}</ng-container>
            </div>
            <div class="table-wrapper inner-table inner-table-sale">
              <ngx-datatable #table id="{{row.idSale}}" class='material expandable data-table' [columnMode]="'force'" [headerHeight]="50"
                [footerHeight]="50" [rowHeight]="70" [scrollbarH]="true" [scrollbarV]="false" [rows]="row.salesDetails"
                [limit]="tableRecordNumbers" [messages]="my_messages">
                <ngx-datatable-row-detail #mySubDetailRow>
                  <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                    <div class="gray third-table-position" *ngIf="row.instalmentRefs && row.instalmentRefs.length">
                      <div class="inner-table-title">{{'sale.refund_debit_details' | translate}}</div>
                      <div class="table-wrapper inner-table inner-table-sale">
                        <ngx-datatable #subTable class='material expandable data-table' [columnMode]="'force'" [headerHeight]="50"
                        [footerHeight]="50" [rowHeight]="70" [scrollbarH]="true" [scrollbarV]="false" [rows]="row.instalmentRefs"
                        [limit]="tableRecordNumbers" [messages]="my_messages">
                          <ngx-datatable-column name="N° / {{'sale.state' | translate}}" [width]="230" [resizeable]="false" [sortable]="false" [draggable]="false">
                            <ng-template ngx-datatable-header-template>
                              <div class="row">
                                <div class="col-3">
                                  N°
                                </div>
                                <div class="col-9">
                                  {{'sale.state' | translate}}
                                </div>
                              </div>
                            </ng-template>
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                              <div class="row align-items-center">
                                <div class="col-3">
                                  <div>{{rowIndex + 1}}</div>
                                </div>
                                <div class="col-9">
                                  <div>
                                    <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{ 'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
                                    <div class="mt-1" *ngIf="( row.paymentType != 'RECHARGE_EXT_REFUND' && row.instalmentStatus == 'PENDING_REFUND' && canCompleteRefundPayment(row) )">
                                      <button class="blue-bg details-button details-button-small" (click)="completeRefundInstalment(row)">
                                        <span>{{'sale.label_complete_refund' | translate}}</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="100" [resizeable]="false" [sortable]="false" [draggable]="false">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                              <div>{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column name="{{'sale.request' | translate}} / {{'sale.refunded' | translate}} / {{'sale.debited' | translate}}" [width]="400" [resizeable]="false" [sortable]="false" [draggable]="false">
                            <ng-template ngx-datatable-header-template>
                              <div class="row">
                                <div class="col-5">
                                  {{'sale.request' | translate}}
                                </div>
                                <div class="col-7">
                                  {{'sale.refunded' | translate}} / {{'sale.debited' | translate}}
                                </div>
                              </div>
                            </ng-template>
                            <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="row align-items-center">
                                <div class="col-5">
                                  <ng-container *ngIf="row.expiryDate">{{row.expiryDate | date:'dd/MM/yyyy'}}</ng-container>
                                </div>
                                <div class="col-7">
                                  <div class="text-wrap">
                                    <ng-container *ngIf="row.lastTransaction">
                                      <ng-container *ngIf="row.instalmentStatus == 'REFUNDED' || row.instalmentStatus == 'PAID_LOST_DISPUTE' || row.instalmentStatus == 'PAID_WON_DISPUTE' || row.instalmentStatus == 'REFUNDED_DISPUTE'">
                                        {{row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'}}
                                      </ng-container>
                                      <div class="font-size-style text-danger" *ngIf="row.instalmentStatus == 'ERROR_REFUND'">
                                        {{ 'sale.instalment_refund_error' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss',
                                          result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}
                                      </div>
                                      <div class="font-size-style text-warning" *ngIf="row.instalmentStatus == 'PROCESSING_REFUND'">
                                        {{ 'sale.instalment_refund_processing' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'} }}
                                      </div>
                                      <ng-container *ngIf="row.instalmentStatus != 'REFUNDED' && row.instalmentStatus != 'ERROR_REFUND' && row.instalmentStatus != 'PROCESSING_REFUND' && row.instalmentStatus != 'PAID_LOST_DISPUTE'
                                         && row.instalmentStatus != 'PAID_WON_DISPUTE' && row.instalmentStatus != 'REFUNDED_DISPUTE'">-</ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!row.lastTransaction">-</ng-container>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-row-detail>

                <ngx-datatable-column name="N°" [width]="60" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                    <div>{{(rowIndex + 1)}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.state' | translate}}" [width]="170" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template ngx-datatable-header-template>
                    <span>{{'sale.state' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                      <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
                      <div class="mt-1" *ngIf="( row.paymentType != 'RECHARGE_EXT_REFUND' && ( row.instalmentStatus == 'PAID' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY' ) && canRefundPayment(row) )">
                        <button class="blue-bg details-button details-button-small" (click)="openModalPaymentRefund(row)">
                          <span>{{'sale.label_refund_payment' | translate}}</span>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="100" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template ngx-datatable-header-template>
                    <span>{{'sale.amount' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.expiry' | translate}}" [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template ngx-datatable-header-template>
                    <span>{{'sale.expiry' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                      <ng-container *ngIf="row.expiryDate">{{ row.expiryDate | date:'dd/MM/yyyy' }}</ng-container>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="row.paymentType != 'CHARGE_DISPUTE' ? ('sale.collected' | translate) : ('sale.debited' | translate)" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template ngx-datatable-header-template>
                    <span *ngIf="row.paymentType != 'CHARGE_DISPUTE'">{{'sale.collected' | translate}}</span>
                    <span *ngIf="row.paymentType == 'CHARGE_DISPUTE'">{{'sale.debited' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="text-wrap">
                      <ng-container *ngIf="row.lastTransaction">
                        <ng-container *ngIf="row.instalmentStatus == 'PAID' || row.instalmentStatus == 'DISPUTE' || row.instalmentStatus == 'DISPUTED' || row.instalmentStatus == 'PAID_WITHDRAWN_DISPUTE' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY'">
                          <div class="d-flex align-items-center">
                            {{row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'}}
                            <ng-container *ngIf="row.paymentMethodType">
                              <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                                title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                              <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                                title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                              <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                                title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                              <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                                row.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                                title="{{row.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                              <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}}">
                              <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
                              <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
                              <img class="payment-country-icon ml-1" *ngIf="row.paymentMethodCountry" src="../../../../assets/img/country/{{row.paymentMethodCountry}}.png">
                            </ng-container>
                          </div>
                        </ng-container>
                        <div class="font-size-style text-danger" *ngIf="row.instalmentStatus == 'ERROR'">
                          {{ 'sale.instalment_payment_error' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss',
                            result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}
                        </div>
                        <div class="font-size-style text-warning" *ngIf="row.instalmentStatus == 'PROCESSING'">
                          {{ 'sale.instalment_payment_processing' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'} }}
                        </div>
                        <ng-container *ngIf="row.instalmentStatus != 'PAID' && row.instalmentStatus != 'ERROR' && row.instalmentStatus != 'PROCESSING' && row.instalmentStatus != 'DISPUTE' && row.instalmentStatus != 'DISPUTED'
                           && row.instalmentStatus != 'PAID_WITHDRAWN_DISPUTE' && row.instalmentStatus != 'ONGOING_INQUIRY' && row.instalmentStatus != 'CLOSED_INQUIRY'">-</ng-container>
                      </ng-container>
                      <ng-container *ngIf="!row.lastTransaction">-</ng-container>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{'sale.payment_id' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
                  <ng-template ngx-datatable-header-template>
                    <span>{{'sale.payment_id' | translate}}</span>
                  </ng-template>
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div>
                      <div class="font-size-style">{{row.instalmentPaymentId}}</div>
                      <div class="font-size-style">{{row.salePaymentId}}</div>
                      <div class="font-size-style" *ngIf="row.paymentId">{{row.paymentId}}</div>
                      <div class="font-size-style" *ngIf="row.payoutPaymentId">{{row.payoutPaymentId}}</div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <!-- <ngx-datatable-footer *ngIf="true">
                  <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" >
                    pippo<div *ngIf="rowCount > 0">
                      {{ rowCount }} {{ 'datatable_message.lista_totali_righe' | translate }}
                    </div>
                    <div *ngIf="rowCount == 0">
                      {{ 'datatable_message.lista_vuota_righe' | translate }}
                    </div>
                    <datatable-pager
                      [pagerLeftArrowIcon]="'datatable-icon-left'"
                      [pagerRightArrowIcon]="'datatable-icon-right'"
                      [pagerPreviousIcon]="'datatable-icon-prev'"
                      [pagerNextIcon]="'datatable-icon-skip'"
                      [page]="curPage"
                      [size]="pageSize"
                      [count]="rowCount"
                      [hidden]="!((rowCount / pageSize) > 1)"
                      (change)="table.onFooterPage($event)">
                    </datatable-pager>
                  </ng-template>
                </ngx-datatable-footer> -->

              </ngx-datatable>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column name="Service" [width]="250">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div>
            <h4 class="text-clamp" title="{{row.service}}">{{row.service}}</h4>
            <div class="table-font-size">
              <ng-container *ngIf="row.fullName">{{row.fullName}}</ng-container>
              <ng-container *ngIf="!row.fullName">{{row.name}} {{row.surname}}</ng-container>
            </div>
            <div class="table-font-size">{{row.email}}</div>
            <div class="table-font-size text-bold" *ngIf="profile == 'SUPERADMIN' || profile == 'RESELLER'">{{row.merchantCompanyName}}</div>
            <div class="table-font-size text-bold font-italic">
              <ng-container *ngIf="row.fromExternalApplication">{{'sale.from_external_application' | translate}}</ng-container>
              <ng-container *ngIf="row.fromExternal">{{'sale.from_external' | translate}}</ng-container>
              <ng-container *ngIf="row.fromManagement">{{'sale.from_management' | translate}}</ng-container>
              <ng-container *ngIf="row.fromPos">{{'sale.from_pos' | translate}}</ng-container>
              <ng-container *ngIf="!row.fromExternalApplication && !row.fromExternal && !row.fromManagement && !row.fromPos">{{'sale.from_portal' | translate}}</ng-container>
            </div>
            <div class="table-font-size secondary-text-color" *ngIf="row.userEmail">
              {{row.userEmail}} <div class="user-info dark-bg a-center m-0 ml-1">{{ 'user.profile_role.'+row.userProfile | translate }}</div>
            </div>
            <div class="table-font-size secondary-text-color text-truncate" *ngIf="row.externalApplicationName">
              <ng-container *ngIf="row.externalDescription"><span class="text-bold">{{row.externalDescription}}</span> -</ng-container>
              {{row.externalApplicationName}}
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" [width]="150">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="row align-items-center justify-content-center">
            <div class="col-12">
              <div class="d-flex align-items-center justify-content-center">
                <div>
                  <div *ngIf="row.status == 'new' && isExpired(row)" class="status-div status-div-table my-0" [class.expired]="isExpired(row)">{{'sale.sale_status.expired'|translate}}</div>
                  <div *ngIf="row.status == 'new' && !isExpired(row)" class="status-div status-div-table my-0 {{row.status}}">{{'sale.sale_status.'+row.status|translate}}</div>
                  <div *ngIf="row.status != 'active' && row.status != 'new'" class="status-div status-div-table my-0 {{row.status}}">{{'sale.sale_status.'+row.status|translate}}</div>
                  <div *ngIf="row.status == 'active'" class="active_status status-div status-div-table my-0">{{'sale.sale_status.'+row.status|translate}}</div>
                </div>
                <!-- <div class="status-pencil">
                  <app-status-select [allStatus]="allStatus" (hideSelectEvent)="hideSelectEvent(row)" (statusSelectedEvent)="statusSelectedEvent($event,row)"></app-status-select>
                </div> -->
                <div class="d-inline-block ml-1" style="line-height: 1.2;" *ngIf="row.status == 'frozen' || isSaleRefundPending(row)">
                  <ng-template #saleStatusPopoverTemplate>
                    <ng-container *ngIf="row.status == 'frozen'">{{ 'sale.sale_frozen_expiration_detail_popover_desc' | translate: {days_expiration: getSaleFrozenExpirationDays(row)} }}</ng-container>
                    <ng-container *ngIf="row.status == 'frozen' && isSaleRefundPending(row)"><hr class="my-2"></ng-container>
                    <ng-container *ngIf="isSaleRefundPending(row)">{{ 'sale.refund_pending_detail_popover_desc' | translate }}</ng-container>
                  </ng-template>
                  <i class="fa fa-exclamation-circle info-icon" placement="right" container="body" [popover]="saleStatusPopoverTemplate" popoverTitle="{{ 'sale.sale_status_detail_popover_title' | translate }}" [outsideClick]="true"></i>
                  <!--<i class="fa fa-exclamation-circle info-icon" placement="right" container="body" popover="{{ 'sale.refund_pending_detail_popover_desc' | translate }}" popoverTitle="{{ 'sale.refund_pending_detail_popover_title' | translate }}" [outsideClick]="true"></i>-->
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="getSaleRefundStatus(row)">
              <div class="d-flex align-items-center justify-content-center">
                <div class="user-info user-info-block {{getSaleRefundStatus(row)}}">{{'sale.sale_status.'+getSaleRefundStatus(row)|translate}}</div>
              </div>
            </div>
            <div class="col-12" *ngIf="row.paymentMethodType">
              <div class="d-flex align-items-center justify-content-center">
                <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                  title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                  title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                  title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                <img class="payment-icon" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                  row.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                  title="{{row.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                <img class="payment-icon" *ngIf="row.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}}">
                <img class="payment-icon" *ngIf="row.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
                <img class="payment-icon" *ngIf="row.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
                <img class="payment-country-icon ml-1" *ngIf="row.paymentMethodCountry" src="../../../../assets/img/country/{{row.paymentMethodCountry}}.png">
                <ng-template #salePaymentMethodPopoverTemplate>
                  <ng-container *ngIf="isPaymentMethodGoingToExpire(row)">{{ 'sale.payment_method_going_to_expire_popover_desc' | translate }}</ng-container>
                  <ng-container *ngIf="isPaymentMethodExpired(row)">{{ 'sale.payment_method_expired_popover_desc' | translate }}</ng-container>
                </ng-template>
                <i class="fa fa-exclamation-circle info-icon ml-1" *ngIf="isPaymentMethodGoingToExpire(row) || isPaymentMethodExpired(row)" placement="right" container="body" [popover]="salePaymentMethodPopoverTemplate" popoverTitle="{{ 'sale.payment_method_going_to_expire_popover_title' | translate }}" [outsideClick]="true"></i>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="SaleAmount" [width]="150">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center border-left-style">
            <h4>{{row.amount}} <span style="font-size: 12px;">{{row.currency}}</span></h4>
            <div class="table-font-size">{{'sale.total_amount' | translate}}</div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Type" [width]="250">
        <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div>
            <h4 class="text-wrap" *ngIf="row.paymentType=='RATEIZZATO'">{{'sale.type_in_installments' | translate}}</h4>
            <h4 class="text-wrap" *ngIf="row.paymentType=='RICORRENTE'">{{'sale.type_recurrent' | translate}}</h4>
            <h4 class="text-wrap" *ngIf="row.paymentType=='SINGOLO'">{{'sale.type_single' | translate}}</h4>
            <h4 class="text-wrap" *ngIf="row.paymentType=='VARIABLE'">{{'sale.type_variable' | translate}}</h4>
            <h4 class="text-wrap" *ngIf="row.paymentType=='INSTANT'">{{'sale.type_instant' | translate}}</h4>
            <h4 class="text-wrap" *ngIf="row.paymentType=='RECHARGE_EXT_REFUND'">{{'sale.type_topup_refund' | translate}}</h4>
            <h4 class="text-wrap" *ngIf="row.paymentType=='CHARGE_DISPUTE'">{{'sale.type_charge_dispute' | translate}}</h4>
            <ng-container *ngIf="row.paymentType=='RATEIZZATO'">
              <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
              <div class="table-font-size">{{'sale.expiration_first_installment_on' | translate}} {{row.startDate | date:'dd/MM/yyyy'}}</div>
              <div class="table-font-size">
                {{'sale.installment_from' | translate}} {{row.startDate | date:'dd/MM/yyyy'}} {{'sale.installment_to' | translate}} {{row.endDate | date:'dd/MM/yyyy'}} 
              </div>
              <div class="table-font-size">
                <ng-container [ngSwitch]="row.instalmentFrequency">
                  <ng-container *ngSwitchCase="'MENSILE'">{{'sale.installment_frequency_monthly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.installment_frequency_bimonthly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.installment_frequency_quarterly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.installment_frequency_half_yearly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.installment_frequency_yearly' | translate}}</ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="row.paymentType=='RICORRENTE'">
              <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
              <div class="table-font-size">{{'sale.expiration_on' | translate}} {{row.startDate | date:'dd/MM/yyyy'}}</div>
              <div class="table-font-size">
                <ng-container [ngSwitch]="row.instalmentFrequency">
                  <ng-container *ngSwitchCase="'MENSILE'">{{'sale.recurrence_monthly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.recurrence_bimonthly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.recurrence_quarterly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.recurrence_half_yearly' | translate}}</ng-container>
                  <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.recurrence_yearly' | translate}}</ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="row.recurrentFrequency">{{'sale.recurrence_days' | translate: {'days': row.recurrentFrequency} }}</ng-container>
                    <ng-container *ngIf="!row.recurrentFrequency">{{'sale.recurrence_manual' | translate}}</ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="row.paymentType=='SINGOLO' || row.paymentType=='INSTANT' || row.paymentType=='RECHARGE_EXT_REFUND' || row.paymentType=='CHARGE_DISPUTE'">
              <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
              <div class="table-font-size">{{'sale.expiration_on' | translate}} {{row.startDate | date:'dd/MM/yyyy'}}</div>
            </ng-container>
            <ng-container *ngIf="row.paymentType=='VARIABLE'">
              <div class="table-font-size">{{'sale.creation_on' | translate}} {{row.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
              <div class="table-font-size">{{'sale.first_expiration_on' | translate}} {{row.startDate | date:'dd/MM/yyyy'}}</div>
              <div class="table-font-size">{{'sale.last_expiration_on' | translate}} {{row.endDate | date:'dd/MM/yyyy'}}</div>
            </ng-container>
            <!--<h4 *ngIf="row.paymentType=='RATEIZZATO'">{{row.instalmentNumber}}</h4>
            <div *ngIf="row.paymentType=='RATEIZZATO'" class="seco-text-color">{{'sale.rate' | translate}}</div>-->
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <div class="f-right">
            <button class="blue-bg details-button" title="Expand/Collapse Row" (click)="toggleExpandRow(row,expanded)">
              <span *ngIf="!expanded">{{'sale.see_details' | translate}}</span>
              <span *ngIf="expanded">{{'general.close' | translate}}</span>
            </button>
            <a href="#" class="moredd dropdown-toggle nav-link blue-bg sec-butt" data-bs-toggle="dropdown" role="button" aria-expanded="false" style=" text-decoration: none;">
              <span class="caret"></span>
            </a>
            <ul class="dropdown-menu detail-dropdrown" role="menu">
              <li class="nav-item" *ngIf="row.status == 'new' && !isExpired(row) && viewPaymentLink">
                <a class="nav-link edit action_enabled" (click)="sendReminder(row)">{{'sale.label_remember_payment' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="row.status == 'new' && canRequestData(row)">
                <a class="nav-link edit action_enabled" (click)="openModalSaleData(row)">{{'sale.retrieve_data' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="row.status == 'frozen'">
                <a class="nav-link edit action_enabled" (click)="sendChangeReminder(row)">{{'sale.label_remember_change' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="(row.status == 'active' || row.status == 'frozen') && (profile == 'SUPERADMIN' || (merchantDetail && merchantDetail.tos_accepted && merchantDetail.state == 'ACTIVE'))">
                <a class="nav-link edit action_enabled" (click)="cancelSale(row)">{{'sale.cancel_sale' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="row.status == 'active' && canEditSale(row)">
                <a class="nav-link edit action_enabled" (click)="openModalSaleEdit(row)">{{'sale.edit_sale' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="row.status == 'active' && canViewEditPaymentMethod(row)">
                <a class="nav-link edit action_enabled" (click)="openModalEditPaymentMethodLink(row)">{{'sale.label_show_edit_payment_method_link' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="row.status == 'active' && canSendEditPaymentMethod(row)">
                <a class="nav-link edit action_enabled" (click)="sendEditPaymentMethodLink(row)">{{'sale.label_send_edit_payment_method_link' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="(row.status == 'new' || row.status == 'active') && canAddRecurrentInstalment(row)">
                <a class="nav-link edit action_enabled" (click)="openModalAddRecurrentInstalment(row)">{{'sale.add_recurrent_instalment' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="!hasActionToShow(row)">
                <a class="nav-link edit action_disabled">{{'general.label_empty_options' | translate}}</a>
              </li>
            </ul>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- <ngx-datatable-footer *ngIf="true">
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" >
          poppi
          <div *ngIf="rowCount > 0" style="padding-left: 20px;">
            {{ rowCount }} {{ 'datatable_message.lista_totali_righe' | translate }}
          </div>
          <div *ngIf="rowCount == 0" style="padding-left: 20px;">
            {{ 'datatable_message.lista_vuota_righe' | translate }}
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!((rowCount / pageSize) > 1)"
            (change)="table.onFooterPage($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer> -->

    </ngx-datatable>
    <div class="" *ngIf="rows.length == 0">
      <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
    </div>
  </div>
</div>
<div class="export-button">
  <button class="blue-bg details-button export-button-padding" (click)="export()">
    <span>{{'sale.export' | translate}}</span>
  </button>
</div>

<ng-template #saleDataModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'sale.retrieve_data_title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div>{{ 'sale.retrieve_data_text' | translate }}</div>
        <div class="text-center" *ngIf="saleToRetrieveData">
          <qr-code [value]="saleToRetrieveData.token" [size]="200"></qr-code>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #saleEditModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'sale.edit_sale_title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row align-items-center justify-content-between">
      <div class="col-4">
        <h4 class="text-wrap" [innerHTML]="appService.formatLongText(saleToEdit.service)"></h4>
        <div class="table-font-size">
          <ng-container *ngIf="saleToEdit.fullName">{{saleToEdit.fullName}}</ng-container>
          <ng-container *ngIf="!saleToEdit.fullName">{{saleToEdit.name}} {{saleToEdit.surname}}</ng-container>
        </div>
        <div class="table-font-size">{{saleToEdit.email}}</div>
        <div class="table-font-size text-bold font-italic">
          <ng-container *ngIf="saleToEdit.fromExternalApplication">{{'sale.from_external_application' | translate}}</ng-container>
          <ng-container *ngIf="saleToEdit.fromExternal">{{'sale.from_external' | translate}}</ng-container>
          <ng-container *ngIf="saleToEdit.fromManagement">{{'sale.from_management' | translate}}</ng-container>
          <ng-container *ngIf="saleToEdit.fromPos">{{'sale.from_pos' | translate}}</ng-container>
          <ng-container *ngIf="!saleToEdit.fromExternalApplication && !saleToEdit.fromExternal && !saleToEdit.fromManagement && !saleToEdit.fromPos">{{'sale.from_portal' | translate}}</ng-container>
        </div>
        <div class="table-font-size secondary-text-color" *ngIf="saleToEdit.userEmail">
          {{saleToEdit.userEmail}} <div class="user-info dark-bg a-center m-0 ml-1">{{ 'user.profile_role.'+saleToEdit.userProfile | translate }}</div>
        </div>
        <div class="table-font-size secondary-text-color text-truncate" *ngIf="saleToEdit.externalApplicationName">
          <ng-container *ngIf="saleToEdit.externalDescription"><span class="text-bold">{{saleToEdit.externalDescription}}</span> -</ng-container>
          {{saleToEdit.externalApplicationName}}
        </div>
      </div>
      <div class="col-2">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-center">
              <div>
                <div *ngIf="saleToEdit.status == 'active'" class="active_status status-div status-div-table my-0">{{ 'sale.sale_status.'+saleToEdit.status | translate }}</div>
              </div>
              <div class="d-inline-block ml-1" style="line-height: 1.2;" *ngIf="saleToEdit.status == 'frozen' || isSaleRefundPending(saleToEdit)">
                <ng-template #saleToEditStatusPopoverTemplate>
                  <ng-container *ngIf="saleToEdit.status == 'frozen'">{{ 'sale.sale_frozen_expiration_detail_popover_desc' | translate: {days_expiration: getSaleFrozenExpirationDays(saleToEdit)} }}</ng-container>
                  <ng-container *ngIf="saleToEdit.status == 'frozen' && isSaleRefundPending(saleToEdit)"><hr class="my-2"></ng-container>
                  <ng-container *ngIf="isSaleRefundPending(saleToEdit)">{{ 'sale.refund_pending_detail_popover_desc' | translate }}</ng-container>
                </ng-template>
                <i class="fa fa-exclamation-circle info-icon" placement="right" container="body" [popover]="saleToEditStatusPopoverTemplate" popoverTitle="{{ 'sale.sale_status_detail_popover_title' | translate }}" [outsideClick]="true"></i>
                <!--<i class="fa fa-exclamation-circle info-icon" placement="right" container="body" popover="{{ 'sale.refund_pending_detail_popover_desc' | translate }}" popoverTitle="{{ 'sale.refund_pending_detail_popover_title' | translate }}" [outsideClick]="true"></i>-->
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="getSaleRefundStatus(saleToEdit)">
            <div class="d-flex align-items-center justify-content-center">
              <div class="user-info user-info-block {{getSaleRefundStatus(saleToEdit)}} m-0">{{'sale.sale_status.'+getSaleRefundStatus(saleToEdit)|translate}}</div>
            </div>
          </div>
          <div class="col-12" *ngIf="saleToEdit.paymentMethodType">
            <div class="d-flex align-items-center justify-content-center">
              <img class="payment-icon" *ngIf="(saleToEdit.paymentMethodType == 'card' || saleToEdit.paymentMethodType == 'card_present') && saleToEdit.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                title="{{'sale.payment_method_last4' | translate}} {{saleToEdit.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToEdit.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToEdit.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(saleToEdit.paymentMethodType == 'card' || saleToEdit.paymentMethodType == 'card_present') && saleToEdit.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                title="{{'sale.payment_method_last4' | translate}} {{saleToEdit.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToEdit.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToEdit.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(saleToEdit.paymentMethodType == 'card' || saleToEdit.paymentMethodType == 'card_present') && saleToEdit.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                title="{{'sale.payment_method_last4' | translate}} {{saleToEdit.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToEdit.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToEdit.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(saleToEdit.paymentMethodType == 'card' || saleToEdit.paymentMethodType == 'card_present') && saleToEdit.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && saleToEdit.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                saleToEdit.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                title="{{saleToEdit.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{saleToEdit.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToEdit.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToEdit.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="saleToEdit.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{saleToEdit.paymentMethodLast4 || '----'}}">
              <img class="payment-icon" *ngIf="saleToEdit.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
              <img class="payment-icon" *ngIf="saleToEdit.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
              <img class="payment-country-icon ml-1" *ngIf="saleToEdit.paymentMethodCountry" src="../../../../assets/img/country/{{saleToEdit.paymentMethodCountry}}.png">
              <ng-template #saleToEditPaymentMethodPopoverTemplate>
                <ng-container *ngIf="isPaymentMethodGoingToExpire(saleToEdit)">{{ 'sale.payment_method_going_to_expire_popover_desc' | translate }}</ng-container>
                <ng-container *ngIf="isPaymentMethodExpired(saleToEdit)">{{ 'sale.payment_method_expired_popover_desc' | translate }}</ng-container>
              </ng-template>
              <i class="fa fa-exclamation-circle info-icon ml-1" *ngIf="isPaymentMethodGoingToExpire(saleToEdit) || isPaymentMethodExpired(saleToEdit)" placement="right" container="body" [popover]="saleToEditPaymentMethodPopoverTemplate" popoverTitle="{{ 'sale.payment_method_going_to_expire_popover_title' | translate }}" [outsideClick]="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row align-items-center justify-content-center a-center border-left-style">
          <div class="col-12">
            <h4>{{saleToEdit.amount}} <span style="font-size: 12px;">{{saleToEdit.currency}}</span></h4>
            <div class="secondary-text-color">{{'sale.total_amount' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <h4 class="text-wrap" *ngIf="saleToEdit.paymentType=='RATEIZZATO'">{{'sale.type_in_installments' | translate}}</h4>
        <h4 class="text-wrap" *ngIf="saleToEdit.paymentType=='RICORRENTE'">{{'sale.type_recurrent' | translate}}</h4>
        <ng-container *ngIf="saleToEdit.paymentType=='RATEIZZATO'">
          <div class="table-font-size">{{'sale.creation_on' | translate}} {{saleToEdit.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
          <div class="table-font-size">{{'sale.expiration_first_installment_on' | translate}} {{saleToEdit.startDate | date:'dd/MM/yyyy'}}</div>
          <div class="table-font-size">
            {{'sale.installment_from' | translate}} {{saleToEdit.startDate | date:'dd/MM/yyyy'}} {{'sale.installment_to' | translate}} {{saleToEdit.endDate | date:'dd/MM/yyyy'}} 
          </div>
          <div class="table-font-size">
            <ng-container [ngSwitch]="saleToEdit.instalmentFrequency">
              <ng-container *ngSwitchCase="'MENSILE'">{{'sale.installment_frequency_monthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.installment_frequency_bimonthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.installment_frequency_quarterly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.installment_frequency_half_yearly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.installment_frequency_yearly' | translate}}</ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="saleToEdit.paymentType=='RICORRENTE'">
          <div class="table-font-size">{{'sale.creation_on' | translate}} {{saleToEdit.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
          <div class="table-font-size">{{'sale.expiration_on' | translate}} {{saleToEdit.startDate | date:'dd/MM/yyyy'}}</div>
          <div class="table-font-size">
            <ng-container [ngSwitch]="saleToEdit.instalmentFrequency">
              <ng-container *ngSwitchCase="'MENSILE'">{{'sale.recurrence_monthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.recurrence_bimonthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.recurrence_quarterly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.recurrence_half_yearly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.recurrence_yearly' | translate}}</ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngIf="saleToEdit.recurrentFrequency">{{'sale.recurrence_days' | translate: {'days': saleToEdit.recurrentFrequency} }}</ng-container>
                <ng-container *ngIf="!saleToEdit.recurrentFrequency">{{'sale.recurrence_manual' | translate}}</ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <h4 class="mt-4">{{'sale.rate_details' | translate}}</h4>
    <form [formGroup]="saleEditForm">
      <div class="table-wrapper inner-table inner-table-sale" formArrayName="instalments">
        <ngx-datatable class='material expandable data-table' [columnMode]="'force'" [headerHeight]="50"
          [footerHeight]="50" [rowHeight]="70" [scrollbarH]="true" [scrollbarV]="false" [rows]="saleToEdit.salesDetails"
          [limit]="tableRecordNumbers" [messages]="my_messages">

          <ngx-datatable-column name="N°" [width]="60" [resizeable]="false" [sortable]="false" [draggable]="false">
            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
              <div>{{(rowIndex + 1)}}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'sale.state' | translate}}" [width]="170" [resizeable]="false" [sortable]="false" [draggable]="false">
            <ng-template ngx-datatable-header-template>
              <span>{{'sale.state' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div>
                <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="130" [resizeable]="false" [sortable]="false" [draggable]="false">
            <ng-template ngx-datatable-header-template>
              <span>{{'sale.amount' | translate}}</span>
            </ng-template>
            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
              <div [formGroupName]="rowIndex">
                <p class="d-inline-block mb-0" *ngIf="!isInstalmentEditable(row)">{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></p>
                <ng-container *ngIf="isInstalmentEditable(row)">
                  <div class="d-flex align-items-center">
                    <input class="custom-float-label-input custom-float-label-input-inline mw-100" type="number" step="0.1" placeholder=" " formControlName="amount">
                    <span style="font-size: 8px;">{{row.currency}}</span>
                  </div>
                  <div class="text-danger text-wrap" style="line-height: 1.2;" *ngIf="saleEditForm.get('instalments').at(rowIndex).get('amount').touched && saleEditForm.get('instalments').at(rowIndex).get('amount').invalid && saleEditForm.get('instalments').at(rowIndex).get('amount').errors.min">
                    <small>{{ 'sale.instalment_amount_min_error' | translate: {amount: (saleEditFormMinAmount | number:".2-2":language) + ' ' + row.currency} }}</small>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'sale.expiry' | translate}}" [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false">
            <ng-template ngx-datatable-header-template>
              <span>{{'sale.expiry' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div>
                <ng-container *ngIf="row.expiryDate">{{ row.expiryDate | date:'dd/MM/yyyy' }}</ng-container>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'sale.collected' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
            <ng-template ngx-datatable-header-template>
              <span>{{'sale.collected' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="text-wrap">
                <ng-container *ngIf="row.lastTransaction">
                  <ng-container *ngIf="row.instalmentStatus == 'PAID' || row.instalmentStatus == 'DISPUTE' || row.instalmentStatus == 'DISPUTED' || row.instalmentStatus == 'PAID_WITHDRAWN_DISPUTE' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY'">
                    <div class="d-flex align-items-center">
                      {{row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'}}
                      <ng-container *ngIf="row.paymentMethodType">
                        <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                          title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                        <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                        title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                        <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                        title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                        <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                          row.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                          title="{{row.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                        <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}}">
                        <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
                        <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
                        <img class="payment-country-icon ml-1" *ngIf="row.paymentMethodCountry" src="../../../../assets/img/country/{{row.paymentMethodCountry}}.png">
                      </ng-container>
                    </div>
                  </ng-container>
                  <div class="font-size-style text-danger" *ngIf="row.instalmentStatus == 'ERROR'">
                    {{ 'sale.instalment_payment_error' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss',
                      result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}
                  </div>
                  <div class="font-size-style text-warning" *ngIf="row.instalmentStatus == 'PROCESSING'">
                    {{ 'sale.instalment_payment_processing' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'} }}
                  </div>
                  <ng-container *ngIf="row.instalmentStatus != 'PAID' && row.instalmentStatus != 'ERROR' && row.instalmentStatus != 'PROCESSING' && row.instalmentStatus != 'DISPUTE' && row.instalmentStatus != 'DISPUTED'
                      && row.instalmentStatus != 'PAID_WITHDRAWN_DISPUTE' && row.instalmentStatus != 'ONGOING_INQUIRY' && row.instalmentStatus != 'CLOSED_INQUIRY'">-</ng-container>
                </ng-container>
                <ng-container *ngIf="!row.lastTransaction">-</ng-container>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'sale.payment_id' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
            <ng-template ngx-datatable-header-template>
              <span>{{'sale.payment_id' | translate}}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div>
                <div class="font-size-style">{{row.instalmentPaymentId}}</div>
                <div class="font-size-style">{{row.salePaymentId}}</div>
                <div class="font-size-style" *ngIf="row.paymentId">{{row.paymentId}}</div>
                <div class="font-size-style" *ngIf="row.payoutPaymentId">{{row.payoutPaymentId}}</div>
              </div>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>

        <div class="text-danger" *ngIf="saleEditForm.get('instalments').invalid && saleEditForm.get('instalments').errors.notEqual">
          {{ 'sale.instalment_amounts_error' | translate: {instalmentTotalAmount: saleEditFormInstalmentTotalAmount + ' ' + saleToEdit.currency, saleAmount: saleToEdit.amount + ' ' + saleToEdit.currency} }}
        </div>

        <div class="row justify-content-end mt-2">
          <div class="col-md-6">
            <button type="button" class="btn btn-block btn-primary" [disabled]="saleEditForm.invalid || !isSaleDataChanged()" (click)="editSale()">
              {{'sale.edit_sale' | translate}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #addRecurrentInstalmentModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'sale.add_recurrent_instalment_title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row align-items-center justify-content-between">
      <div class="col-4">
        <h4 class="text-wrap" [innerHTML]="appService.formatLongText(saleToAddRecurrentInstalment.service)"></h4>
        <div class="table-font-size">
          <ng-container *ngIf="saleToAddRecurrentInstalment.fullName">{{saleToAddRecurrentInstalment.fullName}}</ng-container>
          <ng-container *ngIf="!saleToAddRecurrentInstalment.fullName">{{saleToAddRecurrentInstalment.name}} {{saleToAddRecurrentInstalment.surname}}</ng-container>
        </div>
        <div class="table-font-size">{{saleToAddRecurrentInstalment.email}}</div>
        <div class="table-font-size text-bold font-italic">
          <ng-container *ngIf="saleToAddRecurrentInstalment.fromExternalApplication">{{'sale.from_external_application' | translate}}</ng-container>
          <ng-container *ngIf="saleToAddRecurrentInstalment.fromExternal">{{'sale.from_external' | translate}}</ng-container>
          <ng-container *ngIf="saleToAddRecurrentInstalment.fromManagement">{{'sale.from_management' | translate}}</ng-container>
          <ng-container *ngIf="saleToAddRecurrentInstalment.fromPos">{{'sale.from_pos' | translate}}</ng-container>
          <ng-container *ngIf="!saleToAddRecurrentInstalment.fromExternalApplication && !saleToAddRecurrentInstalment.fromExternal && !saleToAddRecurrentInstalment.fromManagement && !saleToAddRecurrentInstalment.fromPos">{{'sale.from_portal' | translate}}</ng-container>
        </div>
        <div class="table-font-size secondary-text-color" *ngIf="saleToAddRecurrentInstalment.userEmail">
          {{saleToAddRecurrentInstalment.userEmail}} <div class="user-info dark-bg a-center m-0 ml-1">{{ 'user.profile_role.'+saleToAddRecurrentInstalment.userProfile | translate }}</div>
        </div>
        <div class="table-font-size secondary-text-color text-truncate" *ngIf="saleToAddRecurrentInstalment.externalApplicationName">
          <ng-container *ngIf="saleToAddRecurrentInstalment.externalDescription"><span class="text-bold">{{saleToAddRecurrentInstalment.externalDescription}}</span> -</ng-container>
          {{saleToAddRecurrentInstalment.externalApplicationName}}
        </div>
      </div>
      <div class="col-2">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-center">
              <div>
                <div *ngIf="saleToAddRecurrentInstalment.status == 'active'" class="active_status status-div status-div-table my-0">{{ 'sale.sale_status.'+saleToAddRecurrentInstalment.status | translate }}</div>
              </div>
              <div class="d-inline-block ml-1" style="line-height: 1.2;" *ngIf="saleToAddRecurrentInstalment.status == 'frozen' || isSaleRefundPending(saleToAddRecurrentInstalment)">
                <ng-template #saleToAddRecurrentInstalmentStatusPopoverTemplate>
                  <ng-container *ngIf="saleToAddRecurrentInstalment.status == 'frozen'">{{ 'sale.sale_frozen_expiration_detail_popover_desc' | translate: {days_expiration: getSaleFrozenExpirationDays(saleToAddRecurrentInstalment)} }}</ng-container>
                  <ng-container *ngIf="saleToAddRecurrentInstalment.status == 'frozen' && isSaleRefundPending(saleToAddRecurrentInstalment)"><hr class="my-2"></ng-container>
                  <ng-container *ngIf="isSaleRefundPending(saleToAddRecurrentInstalment)">{{ 'sale.refund_pending_detail_popover_desc' | translate }}</ng-container>
                </ng-template>
                <i class="fa fa-exclamation-circle info-icon" placement="right" container="body" [popover]="saleToAddRecurrentInstalmentStatusPopoverTemplate" popoverTitle="{{ 'sale.sale_status_detail_popover_title' | translate }}" [outsideClick]="true"></i>
                <!--<i class="fa fa-exclamation-circle info-icon" placement="right" container="body" popover="{{ 'sale.refund_pending_detail_popover_desc' | translate }}" popoverTitle="{{ 'sale.refund_pending_detail_popover_title' | translate }}" [outsideClick]="true"></i>-->
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="getSaleRefundStatus(saleToAddRecurrentInstalment)">
            <div class="d-flex align-items-center justify-content-center">
              <div class="user-info user-info-block {{getSaleRefundStatus(saleToAddRecurrentInstalment)}} m-0">{{'sale.sale_status.'+getSaleRefundStatus(saleToAddRecurrentInstalment)|translate}}</div>
            </div>
          </div>
          <div class="col-12" *ngIf="saleToAddRecurrentInstalment.paymentMethodType">
            <div class="d-flex align-items-center justify-content-center">
              <img class="payment-icon" *ngIf="(saleToAddRecurrentInstalment.paymentMethodType == 'card' || saleToAddRecurrentInstalment.paymentMethodType == 'card_present') && saleToAddRecurrentInstalment.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                title="{{'sale.payment_method_last4' | translate}} {{saleToAddRecurrentInstalment.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToAddRecurrentInstalment.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToAddRecurrentInstalment.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(saleToAddRecurrentInstalment.paymentMethodType == 'card' || saleToAddRecurrentInstalment.paymentMethodType == 'card_present') && saleToAddRecurrentInstalment.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                title="{{'sale.payment_method_last4' | translate}} {{saleToAddRecurrentInstalment.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToAddRecurrentInstalment.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToAddRecurrentInstalment.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(saleToAddRecurrentInstalment.paymentMethodType == 'card' || saleToAddRecurrentInstalment.paymentMethodType == 'card_present') && saleToAddRecurrentInstalment.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                title="{{'sale.payment_method_last4' | translate}} {{saleToAddRecurrentInstalment.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToAddRecurrentInstalment.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToAddRecurrentInstalment.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="(saleToAddRecurrentInstalment.paymentMethodType == 'card' || saleToAddRecurrentInstalment.paymentMethodType == 'card_present') && saleToAddRecurrentInstalment.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && saleToAddRecurrentInstalment.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                saleToAddRecurrentInstalment.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                title="{{saleToAddRecurrentInstalment.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{saleToAddRecurrentInstalment.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(saleToAddRecurrentInstalment.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{saleToAddRecurrentInstalment.paymentMethodExpYear || '----'}}">
              <img class="payment-icon" *ngIf="saleToAddRecurrentInstalment.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{saleToAddRecurrentInstalment.paymentMethodLast4 || '----'}}">
              <img class="payment-icon" *ngIf="saleToAddRecurrentInstalment.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
              <img class="payment-icon" *ngIf="saleToAddRecurrentInstalment.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
              <img class="payment-country-icon ml-1" *ngIf="saleToAddRecurrentInstalment.paymentMethodCountry" src="../../../../assets/img/country/{{saleToAddRecurrentInstalment.paymentMethodCountry}}.png">
              <ng-template #saleToAddRecurrentInstalmentPaymentMethodPopoverTemplate>
                <ng-container *ngIf="isPaymentMethodGoingToExpire(saleToAddRecurrentInstalment)">{{ 'sale.payment_method_going_to_expire_popover_desc' | translate }}</ng-container>
                <ng-container *ngIf="isPaymentMethodExpired(saleToAddRecurrentInstalment)">{{ 'sale.payment_method_expired_popover_desc' | translate }}</ng-container>
              </ng-template>
              <i class="fa fa-exclamation-circle info-icon ml-1" *ngIf="isPaymentMethodGoingToExpire(saleToAddRecurrentInstalment) || isPaymentMethodExpired(saleToAddRecurrentInstalment)" placement="right" container="body" [popover]="saleToAddRecurrentInstalmentPaymentMethodPopoverTemplate" popoverTitle="{{ 'sale.payment_method_going_to_expire_popover_title' | translate }}" [outsideClick]="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row align-items-center justify-content-center a-center border-left-style">
          <div class="col-12">
            <h4>{{saleToAddRecurrentInstalment.amount}} <span style="font-size: 12px;">{{saleToAddRecurrentInstalment.currency}}</span></h4>
            <div class="secondary-text-color">{{'sale.total_amount' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <h4 class="text-wrap" *ngIf="saleToAddRecurrentInstalment.paymentType=='RATEIZZATO'">{{'sale.type_in_installments' | translate}}</h4>
        <h4 class="text-wrap" *ngIf="saleToAddRecurrentInstalment.paymentType=='RICORRENTE'">{{'sale.type_recurrent' | translate}}</h4>
        <ng-container *ngIf="saleToAddRecurrentInstalment.paymentType=='RATEIZZATO'">
          <div class="table-font-size">{{'sale.creation_on' | translate}} {{saleToAddRecurrentInstalment.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
          <div class="table-font-size">{{'sale.expiration_first_installment_on' | translate}} {{saleToAddRecurrentInstalment.startDate | date:'dd/MM/yyyy'}}</div>
          <div class="table-font-size">
            {{'sale.installment_from' | translate}} {{saleToAddRecurrentInstalment.startDate | date:'dd/MM/yyyy'}} {{'sale.installment_to' | translate}} {{saleToAddRecurrentInstalment.endDate | date:'dd/MM/yyyy'}} 
          </div>
          <div class="table-font-size">
            <ng-container [ngSwitch]="saleToAddRecurrentInstalment.instalmentFrequency">
              <ng-container *ngSwitchCase="'MENSILE'">{{'sale.installment_frequency_monthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.installment_frequency_bimonthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.installment_frequency_quarterly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.installment_frequency_half_yearly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.installment_frequency_yearly' | translate}}</ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="saleToAddRecurrentInstalment.paymentType=='RICORRENTE'">
          <div class="table-font-size">{{'sale.creation_on' | translate}} {{saleToAddRecurrentInstalment.saleDate | date:'dd/MM/yyyy HH:mm'}}</div>
          <div class="table-font-size">{{'sale.expiration_on' | translate}} {{saleToAddRecurrentInstalment.startDate | date:'dd/MM/yyyy'}}</div>
          <div class="table-font-size">
            <ng-container [ngSwitch]="saleToAddRecurrentInstalment.instalmentFrequency">
              <ng-container *ngSwitchCase="'MENSILE'">{{'sale.recurrence_monthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'BIMESTRALE'">{{'sale.recurrence_bimonthly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'TRIMESTRALE'">{{'sale.recurrence_quarterly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'SEMESTRALE'">{{'sale.recurrence_half_yearly' | translate}}</ng-container>
              <ng-container *ngSwitchCase="'ANNUALE'">{{'sale.recurrence_yearly' | translate}}</ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngIf="saleToAddRecurrentInstalment.recurrentFrequency">{{'sale.recurrence_days' | translate: {'days': saleToAddRecurrentInstalment.recurrentFrequency} }}</ng-container>
                <ng-container *ngIf="!saleToAddRecurrentInstalment.recurrentFrequency">{{'sale.recurrence_manual' | translate}}</ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <h4 class="mt-4">{{'sale.rate_details' | translate}}</h4>
    <div class="table-wrapper inner-table inner-table-sale">
      <ngx-datatable class="material expandable data-table" [columnMode]="'force'" [headerHeight]="50"
        [footerHeight]="50" [rowHeight]="70" [scrollbarH]="true" [scrollbarV]="false" [rows]="saleToAddRecurrentInstalment.salesDetails"
        [limit]="tableRecordNumbers" [messages]="my_messages">

        <ngx-datatable-column name="N°" [width]="60" [resizeable]="false" [sortable]="false" [draggable]="false">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div>{{(rowIndex + 1)}}</div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'sale.state' | translate}}" [width]="170" [resizeable]="false" [sortable]="false" [draggable]="false">
          <ng-template ngx-datatable-header-template>
            <span>{{'sale.state' | translate}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="130" [resizeable]="false" [sortable]="false" [draggable]="false">
          <ng-template ngx-datatable-header-template>
            <span>{{'sale.amount' | translate}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'sale.expiry' | translate}}" [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false">
          <ng-template ngx-datatable-header-template>
            <span>{{'sale.expiry' | translate}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              <ng-container *ngIf="row.expiryDate">{{ row.expiryDate | date:'dd/MM/yyyy' }}</ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'sale.collected' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
          <ng-template ngx-datatable-header-template>
            <span>{{'sale.collected' | translate}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="text-wrap">
              <ng-container *ngIf="row.lastTransaction">
                <ng-container *ngIf="row.instalmentStatus == 'PAID' || row.instalmentStatus == 'DISPUTE' || row.instalmentStatus == 'DISPUTED' || row.instalmentStatus == 'PAID_WITHDRAWN_DISPUTE' || row.instalmentStatus == 'ONGOING_INQUIRY' || row.instalmentStatus == 'CLOSED_INQUIRY'">
                  <div class="d-flex align-items-center">
                    {{row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'}}
                    <ng-container *ngIf="row.paymentMethodType">
                      <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') != -1" src="../../../../assets/img/payment-icons/visa.png"
                        title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') != -1" src="../../../../assets/img/payment-icons/mastercard.png"
                      title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('amex') != -1" src="../../../../assets/img/payment-icons/amex.png"
                      title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon ml-1" *ngIf="(row.paymentMethodType == 'card' || row.paymentMethodType == 'card_present') && row.paymentMethodCode?.toLowerCase().indexOf('visa') == -1 && row.paymentMethodCode?.toLowerCase().indexOf('mastercard') == -1 &&
                        row.paymentMethodCode?.toLowerCase().indexOf('amex') == -1" src="../../../../assets/img/payment-icons/card_placeholder1.png"
                        title="{{row.paymentMethodCode?.toLowerCase()}} | {{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}} | {{'sale.payment_method_expiration' | translate}} {{(row.paymentMethodExpMonth | number: '2.0-0') || '--'}}/{{row.paymentMethodExpYear || '----'}}">
                      <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'sepa_debit'" src="../../../../assets/img/payment-icons/sepa.png" title="{{'sale.payment_method_last4' | translate}} {{row.paymentMethodLast4 || '----'}}">
                      <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'klarna'" src="../../../../assets/img/payment-icons/klarna.png">
                      <img class="payment-icon ml-1" *ngIf="row.paymentMethodType == 'satispay'" src="../../../../assets/img/payment-icons/satispay.png">
                      <img class="payment-country-icon ml-1" *ngIf="row.paymentMethodCountry" src="../../../../assets/img/country/{{row.paymentMethodCountry}}.png">
                    </ng-container>
                  </div>
                </ng-container>
                <div class="font-size-style text-danger" *ngIf="row.instalmentStatus == 'ERROR'">
                  {{ 'sale.instalment_payment_error' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss',
                    result_transaction : (row.lastTransaction.statusReason ? ('sale.transaction_reason.' + row.lastTransaction.statusReason | translate) : ('sale.transaction_reason.UNKNOWN' | translate))} }}
                </div>
                <div class="font-size-style text-warning" *ngIf="row.instalmentStatus == 'PROCESSING'">
                  {{ 'sale.instalment_payment_processing' | translate: {date_transaction : row.lastTransaction.tsPayment | date: 'dd/MM/yyyy HH:mm:ss'} }}
                </div>
                <ng-container *ngIf="row.instalmentStatus != 'PAID' && row.instalmentStatus != 'ERROR' && row.instalmentStatus != 'PROCESSING' && row.instalmentStatus != 'DISPUTE' && row.instalmentStatus != 'DISPUTED'
                    && row.instalmentStatus != 'PAID_WITHDRAWN_DISPUTE' && row.instalmentStatus != 'ONGOING_INQUIRY' && row.instalmentStatus != 'CLOSED_INQUIRY'">-</ng-container>
              </ng-container>
              <ng-container *ngIf="!row.lastTransaction">-</ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'sale.payment_id' | translate}}" [width]="250" [resizeable]="false" [sortable]="false" [draggable]="false">
          <ng-template ngx-datatable-header-template>
            <span>{{'sale.payment_id' | translate}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              <div class="font-size-style">{{row.instalmentPaymentId}}</div>
              <div class="font-size-style">{{row.salePaymentId}}</div>
              <div class="font-size-style" *ngIf="row.paymentId">{{row.paymentId}}</div>
              <div class="font-size-style" *ngIf="row.payoutPaymentId">{{row.payoutPaymentId}}</div>
            </div>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

      <h4 class="mt-4">{{'sale.new_recurrent_instalment_details' | translate}}</h4>
      <form [formGroup]="addRecurrentInstalmentForm">
        <div class="table-wrapper inner-table inner-table-sale">
          <ngx-datatable class='material expandable data-table' [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="70" [scrollbarH]="true" [scrollbarV]="false" [rows]="recurrentInstalmentsToAdd"
            [limit]="tableRecordNumbers" [messages]="my_messages">
  
            <ngx-datatable-column name="N°" [width]="60" [resizeable]="false" [sortable]="false" [draggable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div>{{row.number}}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'sale.state' | translate}}" [width]="170" [resizeable]="false" [sortable]="false" [draggable]="false">
              <ng-template ngx-datatable-header-template>
                <span>{{'sale.state' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div>
                  <div class="user-info user-info-block {{getInstalmentStatus(row)}} m-0">{{'sale.rate_detail_status.'+getInstalmentStatus(row) | translate}}</div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'sale.amount' | translate}}" [width]="130" [resizeable]="false" [sortable]="false" [draggable]="false">
              <ng-template ngx-datatable-header-template>
                <span>{{'sale.amount' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div>{{row.amount}} <span style="font-size: 8px;">{{row.currency}}</span></div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'sale.expiry' | translate}}" [width]="300" [resizeable]="false" [sortable]="false" [draggable]="false"
              *ngIf="!saleToAddRecurrentInstalment.recurrentFrequency && !saleToAddRecurrentInstalment.instalmentFrequency">
              <ng-template ngx-datatable-header-template>
                <span>{{'sale.expiry' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div>
                  <div class="d-flex align-items-center">
                    <input class="custom-float-label-input custom-float-label-input-inline mw-100" formControlName="expiryDate" type="text" placeholder=""
                      matInput [bsConfig]="bsConfig" bsDatepicker [minDate]="row.minDate" placement="top" autocomplete="off"
                      (bsValueChange)="onNextRecurrenceExpiryDateChange($event)"/>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'sale.expiry_day' | translate}}" [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false"
              *ngIf="saleToAddRecurrentInstalment.instalmentFrequency && !saleToAddRecurrentInstalment.schedulingStartMonth">
              <ng-template ngx-datatable-header-template>
                <span>{{'sale.expiry_day' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div>
                  <div class="d-flex align-items-center">
                    <ng-select class="custom no-float-label custom-float-label-input custom-float-label-input-inline mw-100" placeholder="" formControlName="expiryDay"
                      (change)="onNextRecurrenceExpiryDayChange($event)" appendTo=".modal-body">
                      <ng-option *ngFor="let day of days" [value]="day.number">{{day.number}}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'sale.expiry_expected' | translate}}" [resizeable]="false" [sortable]="false" [draggable]="false"
              *ngIf="saleToAddRecurrentInstalment.recurrentFrequency || saleToAddRecurrentInstalment.instalmentFrequency">
              <ng-template ngx-datatable-header-template>
                <span>{{'sale.expiry_expected' | translate}}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div>
                  <ng-container *ngIf="row.expiryDate">{{ row.expiryDate | date:'dd/MM/yyyy' }}</ng-container>
                </div>
              </ng-template>
            </ngx-datatable-column>
  
          </ngx-datatable>

          <div class="row justify-content-end mt-2">
            <div class="col-md-6">
              <button type="button" class="btn btn-block btn-primary" [disabled]="addRecurrentInstalmentForm.invalid" (click)="addRecurrentInstalment()">
                {{'sale.add_recurrent_instalment' | translate}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #refundModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'sale.refund_payment_title' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        {{ 'sale.instalment_amount' | translate }}
      </div>
      <div class="col-md-6">
        {{ 'sale.instalment_total_refund_amount' | translate }} <i class="fa fa-exclamation-circle info-icon" placement="bottom" container="body"
          popover="{{ 'sale.instalment_total_refund_amount_detail_popover_desc' | translate }}" popoverTitle="{{ 'sale.instalment_total_refund_amount_detail_popover_title' | translate }}" [outsideClick]="true"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 highlighted-text">
        {{ instalmentToBeRefunded.amount | number:".2-2":language }} €
      </div>
      <div class="col-md-6 highlighted-text">
        {{ instalmentToBeRefunded.refundTotalAmount | number:".2-2":language }} €
      </div>
    </div>
    <form [formGroup]="refundForm">
      <div class="row mt-3">
        <div class="col-md-6">
          <label class="label custom-label">{{'sale.refund_type' | translate }}</label>
          <div class="d-flex align-items-center">
            <div class="radio c-radio d-flex">
              <label class="mb-0" for="typeFull">
                <input type="radio" name="type" id="typeFull" formControlName="type" [value]="RefundType.FULL" (change)="onChangeRefundType()">
                <span class="fa fa-circle"></span>{{'sale.refund_type_full' | translate}}
              </label>
            </div>
            <div class="radio c-radio d-flex ml-1">
              <label class="mb-0" for="typePartial">
                <input type="radio" name="type" id="typePartial" formControlName="type" [value]="RefundType.PARTIAL" (change)="onChangeRefundType()">
                <span class="fa fa-circle"></span>{{'sale.refund_type_partial' | translate}}
              </label>
            </div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-md-6" *ngIf="refundForm.get('type').value == RefundType.PARTIAL">
          <div class="has-float-label fl">
            <input class="custom-float-label-input" type="number" step="0.1" placeholder=" " formControlName="amount" id="amount">
            <label class="label custom-label" for="amount"> {{'sale.refund_amount' | translate}} </label>
          </div>
          <div class="text-danger" *ngIf="refundForm.get('amount').touched && refundForm.get('amount').invalid && (refundForm.get('amount').errors.min || refundForm.get('amount').errors.max)">
            <ng-container *ngIf="refundForm.get('amount').errors.min">{{ 'sale.refund_amount_min_error' | translate: {amount: (refundFormMinAmount | number:".2-2":language) + ' €'} }}</ng-container>
            <ng-container *ngIf="refundForm.get('amount').errors.max">{{ 'sale.refund_amount_max_error' | translate: {amount: (refundFormMaxAmount | number:".2-2":language) + ' €'} }}</ng-container>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mt-2">
        <div class="col-md-6">
          <button type="button" class="btn btn-block btn-primary" [disabled]="refundForm.invalid" (click)="refundInstalment()">
            {{'sale.refund_payment' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #infoModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <i class="fa fa-info-circle"></i> {{ 'sale.info_title' | translate }}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>{{ 'sale.info_message' | translate }}</div>
    <form [formGroup]="infoForm">
      <div class="row mt-3">
        <div class="col-12">
          <div class="checkbox c-checkbox d-flex">
            <label class="mb-0" for="infoShow">
              <input type="checkbox" id="infoShow" formControlName="hideInfo">
              <span class="fa fa-check check-icon-size"></span>{{'sale.not_show_anymore' | translate}}
            </label>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mt-2">
        <div class="col-md-6">
          <button type="button" class="btn btn-block btn-primary" [disabled]="!infoForm.valid" (click)="saveHideInfo()">
            {{'sale.confirm_choice' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
